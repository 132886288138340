import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"
import AffiliatesRoutes from "./Affiliates/AffiliatesRoutes"

const AffiliateScreen =LazyLoad(()=>import("../../Affiliate/AffiliateScreen")) 
const AffiliateOrganizations =LazyLoad(()=>import("./AfffiliateOrganizations/AffiliateOrganizations")) 
const SingleOrganizationAffiliates =LazyLoad(()=>import("./AfffiliateOrganizations/SingleOrganizationAffiliates/SingleOrganizationAffiliates")) 
const AffiliateManagementDashboard =LazyLoad(()=>import("./AffiliateManagementDashboard")) 
const AffiliateTypes =LazyLoad(()=>import("./AffiliateTypes/AffiliateTypes")) 
const AffiliatesScreens =LazyLoad(()=>import("./Affiliates/AffiliatesScreens")) 
const InviteAffiliates =LazyLoad(()=>import("./Affiliates/InviteAffiliates/InviteAffiliates")) 

const AffiliateManagementRoutes = [
  {
    path: 'dashboard',
    element: <AffiliateManagementDashboard />
  },

  {
    path: 'affiliates/organizations',
    element: <AffiliateOrganizations />
  },
  {
    path: 'affiliateOrganization/:organizationID/affiliates',
    element: <SingleOrganizationAffiliates />
  },
  {
    path: 'types',
    element: <AffiliateTypes />,
  },
  {
    path: 'affiliateDashboardDesign',
    element: <AffiliateScreen />,
  },
  {
    path: 'affiliates',
    element: <AffiliatesScreens />,
    children: AffiliatesRoutes
  },
  {
    path: "inviteAffiliates",
    element: <InviteAffiliates />
  }

]

export default AffiliateManagementRoutes