import { takaFormatter } from 'antopolis-utils/helpers/Formatter';

function AmountProgress({
  lockedProgress,
  highProgress,
  lowProgress,
  potentialProgress,
  baseAmountDivided,
}) {
  return (
    <div className='progress-container'>
      <div className='progress-bar-container'>
        <div
          className='progress-bar locked-progress'
          style={{ width: `${lockedProgress}%` }}
        />
        <div
          className='progress-bar high-progress'
          style={{
            width: `${highProgress}%`,
            left: `${lockedProgress}%`,
          }}
        />
        <div
          className='progress-bar low-progress'
          style={{
            width: `${lowProgress}%`,
            left: `${lockedProgress + highProgress}%`,
          }}
        />
        <div
          className='progress-bar potential-progress'
          style={{
            width: `${potentialProgress}%`,
            left: `${lockedProgress + highProgress + lowProgress}%`,
          }}
        />
      </div>

      <div className='progress-labels'>
        {Array.from({ length: 11 }, (_, i) => i * 10).map((percent) => (
          <div key={percent} className='label'>{`${takaFormatter(
            (baseAmountDivided * percent) / 100,
          )}`}</div>
        ))}
      </div>
    </div>
  );
}

export default AmountProgress;
