import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad";
import InvestorManagementRoutes from "./InvestorManagement/InvestorManagementRoutes";

const InvestorManagementScreens = LazyLoad(() => import("./InvestorManagement/InvestorManagementScreens"))
const MemberOfParliaments = LazyLoad(() => import("./MemberOfParliaments/MemberOfParliaments"))
const Ministers = LazyLoad(() => import("./Ministers/Ministers"))
const StackholderDashboard = LazyLoad(() => import("./StackholderDashboard"))



const StackholderRoutes = [
  {
    path: "dashboard",
    element: <StackholderDashboard />,
  },
  {
    path: "investorManagement",
    element: <InvestorManagementScreens />,
    children: InvestorManagementRoutes,
  },
  {
    path: "MemberOfParliaments",
    element: <MemberOfParliaments />,
  },
  {
    path: "ministers",
    element: <Ministers />,
  },
];

export default StackholderRoutes;
