import { useEffect, useState } from "react"
import { RiImage2Line } from "react-icons/ri";
import { CRUDButton } from "anthill-component-library/dist/buttons";
import { CLUseNavigate } from "anthill-component-library/dist/helpers";
import { useAxiosInstance } from "anthill-component-library/dist/useAxiosInstance-8fca8442";
import { DESIGNATIONS_API } from "../../../../../../Utilities/APIs/BizDev/UsersAPI/EmployeesAPI/CompanyStructureAPI/CompanyStructureAPIs";
import { hrRecruitmentRoute } from "../RecruitmentDashboard";
import { CRUDTable, CRUDTableHeader, CRUDth, ShortTextCell } from "anthill-component-library/dist/crudBuilder";

function DesignationFbGroup() {
    const [designations, setDesignations] = useState(null)

    const navigate = CLUseNavigate()
    const axiosInstance = useAxiosInstance()
    useEffect(() => {
        async function fetchAndSetDesignations() {
            const { data } = await axiosInstance.get(DESIGNATIONS_API)
            setDesignations(data);
        }
        fetchAndSetDesignations();
    }, [axiosInstance]);


    return (

        <div className="screen_wrapper">

            <div className="screen_header" >
                <h1 className="screen_heading">

                    Designations ({designations?.length} in total)
                </h1>
            </div>


            <CRUDTable >
                <CRUDTableHeader>
                    <CRUDth th="Name" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>

                <tbody>
                    {designations?.map((designation) => (
                        <tr key={designation._id}>
                            <ShortTextCell text={designation?.name} />
                            <td>
                                <div className="action_buttons_wrapper">

                                    <div className="action_buttons">

                                        <CRUDButton
                                            handleClick={() =>
                                                navigate(`${hrRecruitmentRoute}fbGroups/${designation?._id}`)
                                            }
                                        >
                                            <RiImage2Line />
                                        </CRUDButton>
                                    </div>


                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </CRUDTable>


        </div>
    )
}

export default DesignationFbGroup