import LazyLoad from "../../../../../Partials/LazyLoad/LazyLoad"

const SlackChannels = LazyLoad(() => import("./SlackChannels/SlackChannels"))
const SlackDashboard = LazyLoad(() => import("./SlackDashboard"))



const SlackRoutes = [
  {
    path: 'dashboard',
    element: <SlackDashboard />
  },

  {
    path: 'slackChannels',
    element: <SlackChannels />
  },

]

export default SlackRoutes