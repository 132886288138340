import { useState } from 'react';
import useFetchRevenueTarget from '../Utils/useFetchMonthlyTarget';
import useFormStateUtils from '../../../../../Utilities/Hooks/useFormStateUtils';
import { getCurrentMonth, monthsMap } from '../Utils/monthData';

import Heading from '../Components/Heading/Heading';
import TargetEntries from '../Components/TargetEntries/TargetEntries';
import TargetModals from '../Components/TargetModals/TargetModals';


function RevenuTarget() {
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(getCurrentMonth());
    const [cashTargetCreateForm, setcashTargetCreateForm] = useState(false)
    const [cashTargetUpdateForm, setcashTargetUpdateForm] = useState(false)
    const [editableData, setEditableData] = useState(null);

    const {
        showCreateForm, setShowCreateForm,
        showUpdateForm, setShowUpdateForm,
        showArchiveForm, setShowArchiveForm,
        triggerFetch, axiosInstance, toggleFetch,
    } = useFormStateUtils();

    const { data, targetID } = useFetchRevenueTarget(year, monthsMap[month], toggleFetch);

    return (
        <section className='cash_target mx-auto'>
            <Heading
                data={data}
                month={month}
                year={year}
                setMonth={setMonth}
                setYear={setYear}
                setcashTargetCreateForm={setcashTargetCreateForm}
                setcashTargetUpdateForm={setcashTargetUpdateForm}
                setEditableData={setEditableData}
            />

            <TargetEntries
                data={data}
                setShowCreateForm={setShowCreateForm}
                setShowUpdateForm={setShowUpdateForm}
                setEditableData={setEditableData}
            />

            <TargetModals
                targetCreateForm={cashTargetCreateForm}
                setTargetCreateForm={setcashTargetCreateForm}
                targetUpdateForm={cashTargetUpdateForm}
                setTargetUpdateForm={setcashTargetUpdateForm}
                showCreateForm={showCreateForm}
                setShowCreateForm={setShowCreateForm}
                showUpdateForm={showUpdateForm}
                setShowUpdateForm={setShowUpdateForm}
                showArchiveForm={showArchiveForm}
                setShowArchiveForm={setShowArchiveForm}
                targetID={targetID}
                triggerFetch={triggerFetch}
                year={year}
                month={monthsMap[month]}
                editableData={editableData}
                axiosInstance={axiosInstance}
            />


        </section>
    );
}

export default RevenuTarget;
