import { useEffect, useState } from "react";
import { CRUDTable } from "anthill-component-library/dist/crudBuilder";

import AssetsWIshlistModals from "./AssetsWIshlistModals/AssetsWIshlistModals";
// import { ASSETS_WISHLIST_APIS } from "../../../../../Utilities/APIs/Gamma/OfficeManagement/OfficeManagementAPIs";
import useFormStateUtils from "../../../../../Utilities/Hooks/useFormStateUtils";
import HeaderWithtFilterButton from "../../../../Partials/HeaderWithFilterButton/HeaderWithtFilterButton";
import TableHeader from "../../../../Partials/Table/TableHeader/TableHeader";
import TableBody from "../../../../Partials/Table/TableBody/TableBody";
import { ASSETS_WISHLIST_APIS } from "../../../../../Utilities/APIs/Gamma/OfficeManagement/OfficeManagementAPIs";


function AssetsWishlist() {

    const [techProducts, setTechProducts] = useState(null)
    const {
        showCreateForm, setShowCreateForm,
        showUpdateForm, setShowUpdateForm,
        showViewSection, setShowViewSection,
        showArchiveForm, setShowArchiveForm,
        search, setSearch,
        showSearch, setShowSearch,
        showFilterProject, setShowFilterProject,
        targetID, setTargetID,
        filter, setFilter,
        toggleFetch,
        isActive, setIsActive,
        triggerFetch, portrait, setShowActionButton, showActionButton,
        axiosInstance,
    } = useFormStateUtils({ filterInitialValue: "active" })


    useEffect(() => {
        async function fetchAndSetTechProducts() {
            const { data } = await axiosInstance.get(`${ASSETS_WISHLIST_APIS}?filter=${filter}&search=${search}`);
            setTechProducts(data);
        }
        fetchAndSetTechProducts();
    }, [toggleFetch, axiosInstance, filter, search]);

    return (
        <div className="screen_wrapper">

            <HeaderWithtFilterButton
                filter={filter}
                setFilter={setFilter}
                filterValues={["active", "inactive"]}
                search={search}
                setSearch={setSearch}
                showSearch={showSearch}
                setShowSearch={setShowSearch}
                setShowCreateForm={setShowCreateForm}
                setShowFilterProject={setShowFilterProject}
                showFilterProject={showFilterProject}
                headingText={`Asset Wishlists (${techProducts?.length > 0 ? techProducts?.length : 0} in total)`}
            />

            <CRUDTable>
                <TableHeader headers={["Name", "Amount", "Link"]} />
                <TableBody
                    items={techProducts}
                    displayItems={[
                        { value: "name" },
                        { value: "amount" },
                        { value: "link" },

                    ]}
                    targetID={targetID}
                    setTargetID={setTargetID}
                    setShowViewSection={setShowViewSection}
                    setShowUpdateForm={setShowUpdateForm}
                    portrait={portrait}
                    setIsActive={setIsActive}
                    showActionButton={showActionButton}
                    setShowActionButton={setShowActionButton}
                    setShowArchiveForm={setShowArchiveForm}
                />

            </CRUDTable>

            <AssetsWIshlistModals
                setShowArchiveForm={setShowArchiveForm}
                showArchiveForm={showArchiveForm}
                showCreateForm={showCreateForm}
                setShowCreateForm={setShowCreateForm}
                showViewSection={showViewSection}
                setShowViewSection={setShowViewSection}
                showUpdateForm={showUpdateForm}
                setShowUpdateForm={setShowUpdateForm}
                targetID={targetID}
                triggerFetch={triggerFetch}
                isActive={isActive}
            />

        </div>
    )
}

export default AssetsWishlist


