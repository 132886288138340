// Array of all months
export const monthsArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

// Object mapping month names to their indices
export const monthsMap = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
};

// Function to get the current month as a string
export function getCurrentMonth() {
    const date = new Date();
    // Returns month index (0-11)
    const monthIndex = date.getMonth();
    // Returns month name
    return monthsArray[monthIndex];
}
