import LazyLoad from "../../../Partials/LazyLoad/LazyLoad"

const Feedbacks =LazyLoad(()=>import("./Feedbacks/Feedbacks")) 
const RNDDashboard =LazyLoad(()=>import("./RNDDashboard")) 

const RNDRoutes = [
    {
      path: 'dashboard',
      element: <RNDDashboard />
    },
    {
      path: 'feedbacks',
      element: <Feedbacks />
    },
    
  ]

export default RNDRoutes