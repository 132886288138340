import LazyLoad from "../../../Partials/LazyLoad/LazyLoad"
import AffiliateManagementRoutes from "./AffiliateManagement/AffiliateManagementRoutes"
import CRMRoutes from "./CRM/CRMRoutes"

const AcquisitionDashboard =LazyLoad(()=>import("./AcquisitionDashboard")) 
const AffiliateManagementScreens =LazyLoad(()=>import("./AffiliateManagement/AffiliateManagementScreens")) 
const CRMScreens =LazyLoad(()=>import("./CRM/CRMScreens")) 
const ConsultancyRequests =LazyLoad(()=>import("./ConsultancyRequests/ConsultancyRequests")) 
const Services =LazyLoad(()=>import("./Services/Services")) 


const AcquisitionRoutes = [
    {
      path: 'dashboard',
      element: <AcquisitionDashboard />
    },
    {
      path: 'affiliateProgram',
      element: <AffiliateManagementScreens />,
      children: AffiliateManagementRoutes
    },
    {
      path: 'services',
      element: <Services />,
      
    },
   
    {
      path: 'consultancyRequests',
      element: <ConsultancyRequests />,
      
    },
    {
      path: 'crm',
      element: <CRMScreens />,
      children: CRMRoutes

    },

    
   
  ]

export default AcquisitionRoutes