import { RiAddCircleFill } from 'react-icons/ri';
import ProgressBar from '../ProgressBar/Progressbar';
import { CLUseLocation } from 'anthill-component-library/dist/helpers';
import { useState } from 'react';
import { takaFormatter } from 'antopolis-utils/helpers/Formatter';
import { calculateAmountProgress } from '../ProgressBar/utils/calculateAmountProgress';
import SwitchMonth from './SwitchMonth';

function TargetEntries({
  data,
  setShowCreateForm,
  setShowUpdateForm,
  setEditableData,
  setViewForm,
  month,
  setMonth,
  year,
  setYear,
}) {
  const [filter, setFilter] = useState('Locked');
  const { pathname } = CLUseLocation();

  const isCashTarget = pathname === '/main/finance/cashTarget';
  const isRevenueTarget = pathname === '/main/finance/revenueTarget';

  if (isCashTarget && !data?.cashTarget) {
    return null;
  }

  if (isRevenueTarget && !data?.revenueTarget) {
    return null;
  }

  const handleFilter = (probability) => {
    switch (probability) {
      case 'Locked':
        setFilter('Locked');
        break;
      case 'High':
        setFilter('High');
        break;
      case 'Low':
        setFilter('Low');
        break;
      case 'Potential':
        setFilter('Potential');
        break;
      default:
        setFilter('Locked');
        break;
    }
  };
  const { amount } = calculateAmountProgress(
    [],
    data?.cashTargetEntries?.filter((entry) => entry.isActive),
  );

  const probabilities = ['Locked', 'High', 'Low', 'Potential'];

  return (
    <>
      <ProgressBar
        data={data?.cashTargetEntries?.filter((entry) => entry.isActive)}
        target={data?.cashTarget || data?.revenueTarget}
        year={data?.year}
        month={data?.month}
        filter={filter}
      />

      <div
        className='data_table_inner cash-target-data_table_inner'
        style={{ height: '66dvh' }}
      >
        <div className='entry-grid-container'>
          {probabilities.map((probability, index) => (
            <div key={probability} className='grid-section'>
              <div
                className={`grid-title heading-${probability} ${
                  probability === filter && `active-${filter}`
                }`}
                onClick={() => handleFilter(probability)}
              >
                {probability} ({`${takaFormatter(amount[index])}`})
              </div>
              <div className='grid-row-container'>
                {data?.cashTargetEntries
                  ?.filter(
                    (entry) =>
                      entry?.probability === probability && entry?.isActive,
                  )
                  ?.map((entry, index) => (
                    <div
                      key={index}
                      className='grid-row'
                      onClick={() => {
                        setShowUpdateForm(true);
                        setEditableData(entry);
                      }}
                    >
                      <span>{` ${entry?.name}`}</span>
                      <span>{`${takaFormatter(entry?.amount)}`}</span>
                    </div>
                  ))}
              </div>
              {/*add/see all */}
              <div className='cash-target-btn-group'>
                <button
                  className='btn_create'
                  onClick={() => {
                    setEditableData(probability);
                    setShowCreateForm(true);
                  }}
                >
                  <span>{`Add Item`}</span>
                  <RiAddCircleFill />
                </button>
                <button
                  className='btn_create '
                  onClick={() => {
                    setEditableData({
                      data: data?.cashTargetEntries?.filter(
                        (entry) =>
                          entry?.probability === probability && entry?.isActive,
                      ),
                      probability,
                    });
                    setViewForm(true);
                  }}
                >
                  <span>{`See All`}</span>
                  {/* <RiAddCircleFill /> */}
                </button>
              </div>
            </div>
          ))}
        </div>
        <SwitchMonth month={month} setMonth={setMonth} year={year} setYear={setYear} />
      </div>
    </>
  );
}

export default TargetEntries;
