import { useState } from 'react';
import { CLUseLocation } from 'anthill-component-library/dist/helpers';
import { NumberInput } from 'anthill-component-library/dist/inputs';
import { Form } from 'anthill-component-library/dist/layouts';
import { useAxiosInstance } from 'anthill-component-library/dist/hooks';
import { FormSubmitButton } from 'anthill-component-library/dist/buttons';

import { CASH_TARGET_APIS } from '../../../../../../Utilities/APIs/Gamma/Finance/CashTarget/CashTargetApi';
import { REVENUE_TARGET_APIS } from '../../../../../../Utilities/APIs/Gamma/RevenuTarget/RevenueTargetAPIs';
import ErrorMessage from '../../../../../Partials/ErrorMessage/ErrorMessage';

function CreateTargetForm({
    triggerFetch,
    setShowCreateForm,
    edit,
    year,
    month,
}) {
    const [targetAmount, setTargetAmount] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const { pathname } = CLUseLocation();

    // Store the condition in a variable
    const isCashTarget = pathname === '/main/finance/cashTarget';
    const api = isCashTarget ? CASH_TARGET_APIS : REVENUE_TARGET_APIS;

    async function handleSubmit(e) {
        e.preventDefault();
        setError(null);

        if (!targetAmount) {
            setError(`Must Add ${isCashTarget ? 'Cash' : 'Revenue'} Target Amount`);
            return;
        }

        setIsDisabled(true);

        const itemData = {
            month,
            year,
            [isCashTarget ? 'cashTarget' : 'revenueTarget']: targetAmount,
        };

        try {
            const response = await axiosInstance.post(api, itemData);
            if (response) {
                triggerFetch();
                setShowCreateForm(false);
                setIsDisabled(false);
                setError('');
            }
        } catch (error) {
            setIsDisabled(false);
            if (error.response) {
                setError(
                    error.response.data.message || 'An error occurred. Please try again.',
                );
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <NumberInput
                    label={isCashTarget ? 'Cash Target Amount' : 'Revenue Target Amount'}
                    value={targetAmount}
                    placeholder={isCashTarget ? 'Enter Cash Target Amount' : 'Enter Revenue Target Amount'}
                    setState={setTargetAmount}
                />

                <ErrorMessage error={error} />

                <FormSubmitButton
                    text={`${edit ? 'Update' : 'Create'} ${isCashTarget ? 'Cash Target' : 'Revenue Target'}`}
                    disabled={isDisabled}
                />
            </Form>
        </div>
    );
}

export default CreateTargetForm;
