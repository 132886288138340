import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"
import AttendanceUnitRoutes from "./AttendanceUnit/AttendanceUnitRoutes"
import HolidayRoutes from "./Holidays/HolidayRoutes"

const AttendanceDashboard = LazyLoad(() => import("./AttendanceDashboard"))
const AttendanceUnitScreens = LazyLoad(() => import("./AttendanceUnit/AttendanceUnitScreens"))
const HolidayScreens = LazyLoad(() => import("./Holidays/HolidayScreens"))
const AttendenceUsers = LazyLoad(() => import("./AttendenceUsers/AttendenceUsers"))


const AttendanceRoutes = [
  {
    path: 'dashboard',
    element: <AttendanceDashboard />
  },
  {
    path: 'attendanceUnit',
    element: <AttendanceUnitScreens />,
    children: AttendanceUnitRoutes
  },
  {
    path: 'attendenceUsers',
    element: <AttendenceUsers />
  },
  {
    path: 'holidays',
    element: <HolidayScreens />,
    children: HolidayRoutes
  }
]

export default AttendanceRoutes