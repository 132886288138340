import LazyLoad from "../../../../../Partials/LazyLoad/LazyLoad"

const ChangemakerDashboard = LazyLoad(()=>import('./ChangemakerDashboard'))
const ChangemakerRoutes = [
    {
      path: 'dashboard',
      element: <ChangemakerDashboard />
    }
  ]

export default ChangemakerRoutes