import { useState } from 'react';
import useFormStateUtils from '../../../../../Utilities/Hooks/useFormStateUtils';
import useFetchCashTarget from '../Utils/useFetchMonthlyTarget';
import { getCurrentMonth, monthsMap } from '../Utils/monthData';
import Heading from '../Components/Heading/Heading';
import TargetModals from '../Components/TargetModals/TargetModals';
import './CashTarget.css';
import TargetEntries from '../Components/TargetEntries/TargetEntries';

function CashTarget() {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(getCurrentMonth());
  const [cashTargetCreateForm, setcashTargetCreateForm] = useState(false);
  const [cashTargetUpdateForm, setcashTargetUpdateForm] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [viewForm, setViewForm] = useState(false);
  const {
    showCreateForm,
    setShowCreateForm,
    showUpdateForm,
    setShowUpdateForm,
    showArchiveForm,
    setShowArchiveForm,
    triggerFetch,
    axiosInstance,
    toggleFetch,
  } = useFormStateUtils();

  const { data, targetID } = useFetchCashTarget(
    year,
    monthsMap[month],
    toggleFetch,
  );

  return (
    <section className='cash_target mx-auto'>
      <Heading
        data={data}
        month={month}
        year={year}
        setMonth={setMonth}
        setYear={setYear}
        setcashTargetCreateForm={setcashTargetCreateForm}
        setcashTargetUpdateForm={setcashTargetUpdateForm}
        setEditableData={setEditableData}
      />

      <TargetEntries
        data={data}
        setShowCreateForm={setShowCreateForm}
        setShowUpdateForm={setShowUpdateForm}
        setEditableData={setEditableData}
        setViewForm={setViewForm}
        month={month}
        setMonth={setMonth}
        year={year}
        setYear={setYear}
      />

      <TargetModals
        targetCreateForm={cashTargetCreateForm}
        setTargetCreateForm={setcashTargetCreateForm}
        targetUpdateForm={cashTargetUpdateForm}
        setTargetUpdateForm={setcashTargetUpdateForm}
        showCreateForm={showCreateForm}
        setShowCreateForm={setShowCreateForm}
        showUpdateForm={showUpdateForm}
        setShowUpdateForm={setShowUpdateForm}
        showArchiveForm={showArchiveForm}
        setShowArchiveForm={setShowArchiveForm}
        viewForm={viewForm}
        setViewForm={setViewForm}
        targetID={targetID}
        triggerFetch={triggerFetch}
        year={year}
        month={monthsMap[month]}
        editableData={editableData}
        axiosInstance={axiosInstance}
      />
    </section>
  );
}

export default CashTarget;
