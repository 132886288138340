import LazyLoad from "../../../Partials/LazyLoad/LazyLoad"
import AttendanceRoutes from "./Attendance/AttendanceRoutes"
import GanttChartScreens from "./GanttChart/GanttChartScreens"
import RecruitmentRoutes from "./Recruitment/RecruitmentRoutes"

const RecruitmentScreens = LazyLoad(() => import("./Recruitment/RecruitmentScreens"))
const AttendanceScreens = LazyLoad(() => import("./Attendance/AttendanceScreens"))
const HRDashboard = LazyLoad(() => import("./HRDashboard"))

const HRRoutes = [
  {
    path: 'dashboard',
    element: <HRDashboard />
  },
  {
    path: 'attendance',
    element: <AttendanceScreens />,
    children: AttendanceRoutes
  },
  {
    path: 'recruitment',
    element: <RecruitmentScreens />,
    children: RecruitmentRoutes
  },
  {
    path: 'ganttChart',
    element: <GanttChartScreens />,
    // children: RecruitmentRoutes
  }
]

export default HRRoutes