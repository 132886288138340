import LazyLoad from './Partials/LazyLoad/LazyLoad';
import { CLCreateBrowserRouter } from 'anthill-component-library/helpers/master';
import authRoutes from './Screens/AuthScreens/AuthRoutes';
import mainRoutes from './Screens/MainScreens/MainRoutes';


import { MainScreens } from 'anthill-component-library/screens/mainScreens';
import { AuthScreens } from 'anthill-component-library/screens/authScreens';
import { App } from 'anthill-component-library/layouts/master'

const NavItems = LazyLoad(() => import('./Partials/NavItems/NavItems'));


const routes = CLCreateBrowserRouter([
  {
    path: '/',
    element: <App NavItems={<NavItems />} />,
    children: [
      {
        path: '/main',
        element: <MainScreens />,
        children: mainRoutes
      },
      {
        path: '/auth',
        element: <AuthScreens />,
        children: authRoutes
      }
    ]
  }

])

export default routes