import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"

// import AffiliateContacts from "./AffiliateContacts/AffiliateContacts"
const AffiliateScreen = LazyLoad(() => import("../../Affiliate/AffiliateScreen"))
const SBUDashboard = LazyLoad(() => import("./SBUDashboard"))

const SBURoutes = [
  {
    path: 'dashboard',
    element: <SBUDashboard />
  },
  {
    path: 'affiliate',
    element: <AffiliateScreen />
  },
]

export default SBURoutes