import { RiImage2Line } from "react-icons/ri";

export function getNestedValue(item, path) {

    const keys = path.split('.');
    let result = item;

    for (let key of keys) {
        if (result[key] !== undefined) {
            result = result[key];
        } else {
            return 'Not Added';
        }
    }
    return result;
}

export function applyTransform(value, transform) {
    return transform ? transform(value) : value;
}

export function createExtrabutton(item, route, navigate) {
    return [
        {
            handleClick: () => {
                navigate(`${route}/${item._id}`);
            },
            children: <RiImage2Line />,
        },
    ];
}
