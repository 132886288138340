import LazyLoad from "../../../../../../../../Partials/LazyLoad/LazyLoad"

const EmployeeDesignations = LazyLoad(() => import('./EmployeeDesignations/EmployeeDesignations'))
const EmployeeTasks = LazyLoad(() => import('./EmployeeTasks/EmployeeTasks'))
const SingleEmployeeDashboard = LazyLoad(() => import('./SingleEmployeeDashboard'))


const SingleEmployeeRoutes = [
    {
        path: 'dashboard/:employeeID',
        element: <SingleEmployeeDashboard />
    },
    {
        path: 'employeeDesignations/:parentID',
        element: <EmployeeDesignations />
    },
    {
        path: 'tasks/:parentID',
        element: <EmployeeTasks />
    },

]

export default SingleEmployeeRoutes