import LazyLoad from "../../../../../Partials/LazyLoad/LazyLoad"

const EventCategories = LazyLoad(() => import("./EventCategories/EventCategories"))
const EventSubcategories = LazyLoad(() => import("./EventSubcategories/EventSubcategories"))
const Events = LazyLoad(() => import("./Events/Events"))
const FinanceEventsDashboard = LazyLoad(() => import("./FinanceEventsDashboard"))

const FinanceEventsRoutes = [
  {
    path: 'dashboard',
    element: <FinanceEventsDashboard />
  },
  {
    path: 'events',
    element: <Events />,
  },
  {
    path: 'categories',
    element: <EventCategories />,
  },
  {
    path: 'subcategories',
    element: <EventSubcategories />,
  },
]

export default FinanceEventsRoutes