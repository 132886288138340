import LazyLoad from "../../../Partials/LazyLoad/LazyLoad"

const TechTasks = LazyLoad(() => import("../Execution/TechTasks/TechTasks"))
const EmployeeProjectTechTasks = LazyLoad(() => import("./EmployeeProjectTechTasks"))

const EmployeeProjectTechTasksRoutes = [
  {
    path: 'dashboard',
    element: <EmployeeProjectTechTasks />
  },

  {
    path: 'projectTechTasks/:projectID/:categoryID',
    element: <TechTasks />
  },

]

export default EmployeeProjectTechTasksRoutes