
import LazyLoad from "../../../../../Partials/LazyLoad/LazyLoad"
import SubcategoryRoutes from "./Subcategory/SubcategoryRoutes"

const ManageProjectDashboard = LazyLoad(() => import("./ManageProjectsDashboard"))
const SubcategoryScreens = LazyLoad(() => import("./Subcategory/SubcategoryScreens"))



const ManageProjectRoutes = [
    {
        path: 'dashboard',
        element: <ManageProjectDashboard />
    },
    {
        path: 'subcategories/:slug',
        element: <SubcategoryScreens />,
        children: SubcategoryRoutes
    },
    {
        path: 'subcategories/:type/:slug',
        element: <SubcategoryScreens />,
        children: SubcategoryRoutes
    },
    {
        path: 'subcategories',
        element: <SubcategoryScreens />,
        children: SubcategoryRoutes
    },


]

export default ManageProjectRoutes