import React from 'react';
import { RiArrowLeftCircleFill, RiArrowRightCircleFill } from 'react-icons/ri';
import { monthsArray, monthsMap } from '../../Utils/monthData';

function SwitchMonth({ month, setMonth, year, setYear }) {
  const handlePrevMonth = () => {
    if (monthsMap[month] - 1 >= 0) {
      setMonth(monthsArray[monthsMap[month] - 1]);
    }
  };
  const handleNextMonth = () => {
    if (monthsMap[month] - 1 < 12) {
      setMonth(monthsArray[monthsMap[month] + 1]);
    }
  };
  return (
    <div className='switch-month-btn-group'>
      <button className='btn_create' onClick={handlePrevMonth}>
        <RiArrowLeftCircleFill />
      </button>
      <button className='btn_create' onClick={handleNextMonth}>
        <RiArrowRightCircleFill />
      </button>
    </div>
  );
}

export default SwitchMonth;
