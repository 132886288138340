import { AuthContext, LayoutContext } from 'anthill-component-library/dist/contexts';
import { CLUseNavigate } from 'anthill-component-library/dist/helpers';
import { useAxiosInstance, useUseOrientation } from 'anthill-component-library/dist/hooks';
import { useState, useCallback, useContext } from 'react';

function useFormStateUtils({ filterInitialValue = '' } = {}) {

  const { setIsLoading, isLoading } = useContext(LayoutContext)
  const { employee } = useContext(AuthContext)

  const [data, setData] = useState(null)

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [showArchiveForm, setShowArchiveForm] = useState(false)
  const [targetID, setTargetID] = useState(null);

  const [isActive, setIsActive] = useState(null)
  const [search, setSearch] = useState('')
  const [showSearch, setShowSearch] = useState(false)
  const [showFilterProject, setShowFilterProject] = useState(false)

  const [pageNumber, setPageNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [filter, setFilter] = useState(filterInitialValue)
  const [secondFilter, setSecondFilter] = useState('')
  const [toggleFetch, setToggleFetch] = useState(false);


  const axiosInstance = useAxiosInstance();
  const { portrait } = useUseOrientation();
  const [showActionButton, setShowActionButton] = useState(false);
  const navigate = CLUseNavigate()

  const triggerFetch = useCallback(() => {
    setToggleFetch((prevState) => !prevState);
  }, []);

  return {

    employee,
    data, setData,

    // responsive
    portrait,
    showActionButton, setShowActionButton,

    // pagination
    pageNumber, setPageNumber,
    totalItems, setTotalItems,
    totalPages, setTotalPages,


    // form state
    showCreateForm, setShowCreateForm,
    showUpdateForm, setShowUpdateForm,
    showArchiveForm, setShowArchiveForm,
    showViewSection, setShowViewSection,
    showDeleteSection, setShowDeleteSection,

    targetID, setTargetID,
    isLoading, setIsLoading,

    // filtering
    search, setSearch,
    showSearch, setShowSearch,
    showFilterProject, setShowFilterProject,
    isActive, setIsActive,
    filter, setFilter,
    secondFilter, setSecondFilter,

    // refetch
    toggleFetch, setToggleFetch,
    triggerFetch,

    axiosInstance, navigate
  };
}

export default useFormStateUtils;
