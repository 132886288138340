import { useEffect, useRef } from "react";

function SearchInput({ search, setSearch }) {

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <div>
            <input
                ref={inputRef}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="input_field search_input"
                placeholder="Search"
                style={{ width: "200px" }}
            />
        </div>
    )
}

export default SearchInput