import LazyLoad from "../../../../../Partials/LazyLoad/LazyLoad"

const AttendanceUnitDashboard = LazyLoad(() => import("./AttendanceUnitDashboard"))
const DailyAttendance = LazyLoad(() => import("./DailyAttendance/DailyAttendance"))
const CompanyMonthlyAttendance = LazyLoad(() => import("./MonthlyAttendance/CompanyMonthlyAttendance/CompanyMonthlyAttendance"))
const MyMonthlyAttendance = LazyLoad(() => import("./MonthlyAttendance/MyMonthlyAttendance/MyMonthlyAttendance"))
const WeeklyTimelineScreen = LazyLoad(() => import("./WeeklyTimeline/WeeklyTimelineScreen"))


const AttendanceUnitRoutes = [
  {
    path: 'dashboard',
    element: <AttendanceUnitDashboard />
  },
  {
    path: 'companyMonthlyAttendance',
    element: <CompanyMonthlyAttendance />
  },
  {
    path: 'dailyAttendance',
    element: <DailyAttendance />
  },
  {
    path: 'myMonthlyAttendance',
    element: <MyMonthlyAttendance />
  },
  {
    path: 'weeklyTimeline',
    element: <WeeklyTimelineScreen />
  },

]

export default AttendanceUnitRoutes