import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"


const CashBudgetDashboard = LazyLoad(() => import("./CashBudgetDashboard"))
const CbCashFlowPresets = LazyLoad(() => import("./CbCashFlowPresets/CbCashFlowPresets"))
const ManageCashBudget = LazyLoad(() => import("./ManageCashBudget/ManageCashBudget"))
const SingleCashBudget = LazyLoad(() => import("./SingleCashBudget/SingleCashBudget"))
const CbFlowPresetGroups = LazyLoad(() => import("./CbFlowPresetGroup/CbFlowPresetGroups"))
const CbFlowPresetGroupPresets = LazyLoad(() => import("./CbFlowPresetGroup/CbFlowPresetGroupPresets/CbFlowPresetGroupPresets"))

const CashBudgetRoutes = [
  {
    path: 'dashboard',
    element: <CashBudgetDashboard />
  },
  {
    path: 'manageCashBudget',
    element: <ManageCashBudget />
  },
  {
    path: 'singleCashbudget/:cbMonth/:cashBudgetID',
    element: <SingleCashBudget />
  },

  {
    path: 'cbCashFlowPresets',
    element: <CbCashFlowPresets />
  },

  {
    path: 'cbCashFlowPresetGroups',
    element: <CbFlowPresetGroups />
  },
  {
    path: 'cbFlowPresetGroupPresets/:presetGroupID',
    element: <CbFlowPresetGroupPresets />
  }

]

export default CashBudgetRoutes