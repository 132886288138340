import React from 'react';
import { getCurrentMonth } from '../../Utils/monthData';

function ViewModal({ data }) {
  return (
    <>
      <div className='view-entry-grid-container'>
        <span>Name</span>
        <span>Amount</span>
        <span>Date</span>
      </div>
      {data?.map((entry, index) => (
        <div key={index} className='view-entry-grid-container'>
          <span>{`${entry?.name}`}</span>
          <span>{`${entry?.amount}`}</span>
          <span>{`${new Date(entry?.date).getDate()} ${getCurrentMonth(
            new Date(entry?.date).getMonth(),
          )}, ${new Date(entry?.date).getFullYear()}`}</span>
        </div>
      ))}
    </>
  );
}

export default ViewModal;
