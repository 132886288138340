import LazyLoad from "../../../../../../Partials/LazyLoad/LazyLoad"
import ProjectsStatusRoutes from "./ProjectsStatus/ProjectsStatusRoutes"

const ProjectsStatusScreens = LazyLoad(()=>import("./ProjectsStatus/ProjectsStatusScreens")) 
const SubcategoryDashboard = LazyLoad(()=>import( "./SubcategoryDashboard"))

const SubcategoryRoutes = [
    {
        path: 'dashboard',
        element: <SubcategoryDashboard />
    },
    {
        path: 'projectsStatus/:slug',
        element: <ProjectsStatusScreens />,
        children: ProjectsStatusRoutes
    },
    {
        path: 'projectsStatus/:type/:slug',
        element: <ProjectsStatusScreens />,
        children: ProjectsStatusRoutes
    },
    {
        path: 'projectsStatus/',
        element: <ProjectsStatusScreens />,
        children: ProjectsStatusRoutes
    },
    

]

export default SubcategoryRoutes