import { CRUDTableHeader, CRUDth } from "anthill-component-library/dist/crudBuilder"


function TableHeader({ headers, hasActions = true }) {
    return (
        <CRUDTableHeader>
            {headers.map((header, index) => (
                <CRUDth key={index} th={header} />
            ))}
            {
                hasActions && <CRUDth th="Actions" />
            }
        </CRUDTableHeader>
    )
}

export default TableHeader