import LazyLoad from "../../../../../Partials/LazyLoad/LazyLoad"

const HolidayDashboard = LazyLoad(() => import("./HolidayDashboard"))

const HolidayRoutes = [
  {
    path: 'dashboard',
    element: <HolidayDashboard />
  }
]

export default HolidayRoutes