import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"
import FinanceEventsRoutes from "./FinanceEvents/FinanceEventsRoutes"
import TransactionManagementRoutes from "./TransactionManagement/TransactionManagementRoutes"

const BookKeepingDashboard = LazyLoad(() => import("./BookKeepingDashboard"))
const FinanceEventsScreens = LazyLoad(() => import("./FinanceEvents/FinanceEventsScreens"))
const TransactionManagementScreens = LazyLoad(() => import("./TransactionManagement/TransactionManagementScreens"))

const BookKeepingRoutes = [
  {
    path: 'dashboard',
    element: <BookKeepingDashboard />
  },
  {
    path: 'financeEvents',
    element: <FinanceEventsScreens />,
    children: FinanceEventsRoutes
  },
  {
    path: 'transactionManagement',
    element: <TransactionManagementScreens />,
    children: TransactionManagementRoutes
  },
  // {
  //   path: 'journalEntry',
  //   element: <JournalEntry />
  // }
]

export default BookKeepingRoutes