import { RiArrowLeftLine } from "react-icons/ri";
import "./BackButton.css"

function BackButton() {

    function handleGoBack() {
        window.history.back();
    };
    return (
        <button onClick={handleGoBack} className="back_btn">
            <RiArrowLeftLine size={24} />
        </button>
    )
}

export default BackButton