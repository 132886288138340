import LazyLoad from "../../../../../Partials/LazyLoad/LazyLoad"

const ProjectStatusDashboard = LazyLoad(()=>import("./ProjectStatusDashboard")) 
const TechProjects = LazyLoad(()=>import("./TechProjects/TechProjects")) 

const ProjectStatusRoutes = [
    {
      path: 'dashboard',
      element: <ProjectStatusDashboard />
    },
    {
      path: 'techProjects/:status',
      element: <TechProjects />
    }

  ]

  export default ProjectStatusRoutes