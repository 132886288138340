

import { RECRUITMENT_FB_GROUP_APIS } from "../../../../../../Utilities/APIs/Gamma/HR/HRAPIs"
import { CRUDBoilerPlate } from 'anthill-component-library/crud/boilerplate'

function RecruitmentFbGroups() {
    return (
        <CRUDBoilerPlate
            // common props
            api={RECRUITMENT_FB_GROUP_APIS}
            screenTopicSingular='Recruitment Fb Group'
            screenTopicPlural='Recruitment Fb Groups'
            showTotalInHeading

            // field props
            hasName
            hasLink

            // crud props
            allowCreate
            allowUpdate
        />
    )
}

export default RecruitmentFbGroups