import LazyLoad from '../../../../../Partials/LazyLoad/LazyLoad'
import CompanyStructureRoutes from './CompanyStructures/CompanyStructureRoutes'
import ManageEmployeeRoutes from './ManageEmployees/ManageEmployeeRoutes'

const CompanyStructureScreens = LazyLoad(() => import('./CompanyStructures/CompanyStructureScreens'))
const Departments = LazyLoad(() => import('./CompanyStructures/Departments/Departments'))
const AllDesignations = LazyLoad(() => import('./Designations/AllDesignations/AllDesignations'))
const DesignationDashboard = LazyLoad(() => import('./Designations/DesignationDashboard'))
const Designations = LazyLoad(() => import('./Designations/Designations'))
const EmployeeDesignations = LazyLoad(() => import('./Designations/EmployeeDesignations'))
const Responsibilities = LazyLoad(() => import('./Designations/Responsibilities/Responsibilities'))
const EmployeeDashboard = LazyLoad(() => import('./EmployeeDashboard'))
const InviteEmployees = LazyLoad(() => import('./InviteEmployees/InviteEmployees'))
const ManageEmployeeScreens = LazyLoad(() => import('./ManageEmployees/ManageEmployeeScreens'))


const EmployeeRoutes = [
  {
    path: 'dashboard',
    element: <EmployeeDashboard />
  },
  {
    path: 'companyStructures',
    element: <CompanyStructureScreens />,
    children: CompanyStructureRoutes
  },
  {
    path: 'companyStructures',
    element: <Departments />
  },
  {
    path: 'designationsByDepartment/:parentID',
    element: <Designations />
  },
  {
    path: 'responsibilities/:parentID',
    element: <Responsibilities />
  },
  {
    path: 'manageEmployee',
    element: <ManageEmployeeScreens />,
    children: ManageEmployeeRoutes
  },
  {
    path: 'inviteEmployees',
    element: <InviteEmployees />,
  },
  {
    path: 'allDesignations',
    element: < AllDesignations />,
  },
  {
    path: 'designationDashboard/:designationID',
    element: <DesignationDashboard />
  },
  {
    path: 'designationEmployees/:parentID',
    element: <EmployeeDesignations />
  }
]

export default EmployeeRoutes