

import { useState } from 'react';
import {
    DateInput,
    NumberInput,
    SelectInput,
    SelectOption,
    ShortTextInput,
} from 'anthill-component-library/dist/inputs';
import { Form } from 'anthill-component-library/dist/layouts';
import { useAxiosInstance } from 'anthill-component-library/dist/hooks';
import { FormSubmitButton } from 'anthill-component-library/dist/buttons';
import ErrorMessage from '../../../../../Partials/ErrorMessage/ErrorMessage';
import { CASH_TARGET_APIS } from '../../../../../../Utilities/APIs/Gamma/Finance/CashTarget/CashTargetApi';
import { CLUseLocation } from 'anthill-component-library/dist/helpers';
import { REVENUE_TARGET_APIS } from '../../../../../../Utilities/APIs/Gamma/RevenuTarget/RevenueTargetAPIs';

function UpdateEntry({
    editableData,
    triggerFetch,
    setShowUpdateForm,
    targetID,
}) {
    const [name, setName] = useState(editableData?.name);
    const [amount, setAmount] = useState(editableData?.amount);
    const [probability, setProbability] = useState(editableData?.probability);
    const [date, setDate] = useState(editableData?.date?.split('T')[0]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();

    const { pathname } = CLUseLocation()
    const isCashTarget = pathname === '/main/finance/cashTarget';
    const api = isCashTarget ? CASH_TARGET_APIS + 'updateCashTargetEntry/' : REVENUE_TARGET_APIS + 'updateRevenueTargetEntry/';


    async function handleSubmit(e) {
        e.preventDefault();
        setError(null);

        if (!name || !amount || !probability || !date)
            return setError('Must Add (Name,  Amount, Probability, Date)');

        setIsDisabled(true);

        const itemData = {
            name,
            amount,
            probability,
            date,
        };
        try {
            const response = await axiosInstance.patch(api + `${targetID}/${editableData?._id}`, itemData);
            if (response) {
                triggerFetch();
                setShowUpdateForm(false);
                setIsDisabled(false);
                setError('');
            }
        } catch (error) {
            setIsDisabled(false);
            if (error.response) {
                setError(
                    error.response.data.message || 'An error occurred. Please try again.',
                );
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <ShortTextInput
                    label={`Item Name`}
                    value={name}
                    placeholder={`Enter Name`}
                    setState={setName}
                />
                <NumberInput
                    label={`Item Amount`}
                    value={amount}
                    placeholder={`Enter Item Amount`}
                    setState={setAmount}
                />
                <SelectInput
                    label={'Select Probability'}
                    value={probability}
                    setState={setProbability}
                >
                    <SelectOption optionValue='' optionText='Select Type' />
                    <SelectOption optionValue='Locked' optionText='Locked' />
                    <SelectOption optionValue='High' optionText='High' />
                    <SelectOption optionValue='Low' optionText='Low' />
                    <SelectOption optionValue='Potential' optionText='Potential' />
                </SelectInput>

                <DateInput label={'Select Date'} value={date} setState={setDate} />

                <ErrorMessage error={error} />

                <FormSubmitButton text='Update' disabled={isDisabled} />
            </Form>
        </div>
        // </div>
    );
}

export default UpdateEntry;
