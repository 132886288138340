

import { camelCaseToText } from "../../../Utilities/HelperFunctions/camelCaseToText"
import "./SelectFiltering.css"


function SelectFiltering({ values, value, setState }) {
    return (
        <div className="filter_container">

            <select
                value={value}
                className="filter_select"
                onChange={e => setState(e.target.value)}>
                {values.map(value => (
                    <option key={value} value={value}>
                        {camelCaseToText(value)}
                    </option>
                ))}

            </select>
        </div>

    )
}


export default SelectFiltering