import LazyLoad from "../../../../../Partials/LazyLoad/LazyLoad"
import AffiliateStatusRoutes from "./AffiliateStatus/AffiliateStatusRoutes"

const AffiliateOrganizationsAffiliate =LazyLoad(()=>import("./AffiliateOrganizationsAffiliate/AffiliateOrganizationsAffiliate")) 
const AffiliateContacts =LazyLoad(()=>import("./AffiliateStatus/AffiliateContacts/AffiliateContacts")) 
const AffiliateStatusScreens =LazyLoad(()=>import("./AffiliateStatus/AffiliateStatusScreens")) 
const AffiliatesDashboard =LazyLoad(()=>import( "./AffiliatesDashboard"))


const AffiliatesRoutes = [
    {
        path: 'dashboard',
        element: <AffiliatesDashboard />
    },
    {
        path: 'status',
        element: <AffiliateStatusScreens />,
        children: AffiliateStatusRoutes
    },
    {
        path: 'affiliateContacts',
        element: <AffiliateContacts />,
        // children: LocalAffiliatesRoutes
    },

    {
        path: 'affiliateOrganizationAffiliate/:affiliateID',
        element: <AffiliateOrganizationsAffiliate />,
    },


]

export default AffiliatesRoutes