import { useState } from 'react';
import { Form } from 'anthill-component-library/dist/layouts';
import { NumberInput } from 'anthill-component-library/dist/inputs';
import { useAxiosInstance } from 'anthill-component-library/dist/hooks';
import { FormSubmitButton } from 'anthill-component-library/dist/buttons';
import { CASH_TARGET_APIS } from '../../../../../../Utilities/APIs/Gamma/Finance/CashTarget/CashTargetApi';
import { REVENUE_TARGET_APIS } from '../../../../../../Utilities/APIs/Gamma/RevenuTarget/RevenueTargetAPIs';
import { CLUseLocation } from 'anthill-component-library/dist/helpers';
import ErrorMessage from '../../../../../Partials/ErrorMessage/ErrorMessage';

function UpdateTargetForm({
  triggerFetch,
  setShowUpdateForm,
  targetID,
  editableData,
}) {
  const [targetAmount, setTargetAmount] = useState(editableData?.cashTarget || editableData?.revenueTarget);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState(null);
  const axiosInstance = useAxiosInstance();
  const { pathname } = CLUseLocation();

  // Store the condition in a variable
  const isCashTarget = pathname === '/main/finance/cashTarget';
  const api = isCashTarget ? CASH_TARGET_APIS : REVENUE_TARGET_APIS;

  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    if (!targetAmount) {
      setError(`Must Add ${isCashTarget ? 'Cash' : 'Revenue'} Target Amount`);
      return;
    }

    setIsDisabled(true);

    const itemData = {
      [isCashTarget ? 'cashTarget' : 'revenueTarget']: targetAmount,
    };

    try {
      const response = await axiosInstance.patch(api + targetID, itemData);
      if (response) {
        triggerFetch();
        setShowUpdateForm(false);
        setIsDisabled(false);
        setError('');
      }
    } catch (error) {
      setIsDisabled(false);
      if (error.response) {
        setError(
          error.response.data.message || 'An error occurred. Please try again.',
        );
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  }

  return (
    <div className='crate_form'>
      <Form onSubmit={handleSubmit}>
        <NumberInput
          label={`Update ${isCashTarget ? 'Cash' : 'Revenue'} Target Amount`}
          value={targetAmount}
          placeholder={`Enter ${isCashTarget ? 'Cash' : 'Revenue'} Target Amount`}
          setState={setTargetAmount}
        />

        <ErrorMessage error={error} />

        <FormSubmitButton text={`Update ${isCashTarget ? 'Cash' : 'Revenue'} Target`} disabled={isDisabled} />
      </Form>
    </div>
  );
}

export default UpdateTargetForm;
