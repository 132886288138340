import CashTarget from './CashTarget/CashTarget';
import LazyLoad from '../../../Partials/LazyLoad/LazyLoad';
import CashBudgetRoutes from './CashBudget/CashBudgetRoutes';
import BookKeepingRoutes from './BookKeeping/BookKeepingRoutes';
import AccountManagementRoutes from './AccountManagement/AccountManagementRoutes';
import RevenueTarget from './RevenueTarget/RevenueTarget';

const BookKeepingScreens = LazyLoad(() =>
  import('./BookKeeping/BookKeepingScreens'),
);
const CashBudgetScreens = LazyLoad(() =>
  import('./CashBudget/CashBudgetScreens'),
);
const FinanceDashboard = LazyLoad(() => import('./FinanceDashboard'));
const PurchaseInvoices = LazyLoad(() =>
  import('./PurchaseInvoices/PurchaseInvoices'),
);
const TransactionGroup = LazyLoad(() =>
  import('./TransactionGroup/TransactionGroup'),
);
const TransactionItem = LazyLoad(() =>
  import('./TransactionItem/TransactionItem'),
);

const FinanceRoutes = [
  {
    path: 'dashboard',
    element: <FinanceDashboard />,
  },
  {
    path: 'bookKeeping',
    element: <BookKeepingScreens />,
    children: BookKeepingRoutes,
  },
  {
    path: 'accountManagement',
    element: <BookKeepingScreens />,
    children: AccountManagementRoutes,
  },
  {
    path: 'purchaseInvoice',
    element: <PurchaseInvoices />,
  },
  {
    path: 'cashBudget',
    element: <CashBudgetScreens />,
    children: CashBudgetRoutes,
  },
  {
    path: 'transactionItems',
    element: <TransactionItem />,
  },
  {
    path: 'transactionGroups',
    element: <TransactionGroup />,
  },
  {
    path: 'cashTarget',
    element: <CashTarget />,
  },
  {
    path: 'revenueTarget',
    element: <RevenueTarget />,
  },
];

export default FinanceRoutes;
