import {  useState } from 'react';

import { useAxiosInstance } from 'anthill-component-library/dist/hooks';
import { Form } from 'anthill-component-library/dist/layouts';
import {
  NumberInput,
  ShortTextInput,
} from 'anthill-component-library/dist/inputs';
import { FormSubmitButton } from 'anthill-component-library/dist/buttons';
import { ASSETS_WISHLIST_APIS } from '../../../../../../Utilities/APIs/Gamma/OfficeManagement/OfficeManagementAPIs';
import ErrorMessage from '../../../../../Partials/ErrorMessage/ErrorMessage';
import { ImageInput } from 'anthill-component-library/dist/ImageInput-8f19db3d';

function CreateAssetWishlist({ triggerFetch, setShowCreateForm }) {
  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);
  const [link, setLink] = useState('');
  const [image, setImage] = useState('');

  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState(null);

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    if (!name || !amount) {
      setError('Must Add (Name,Amount)');
      return;
    }
    setIsDisabled(true);

    // const itemData = { name, amount, link, image };
    const itemData = new FormData();
    itemData.append('name', name);
    itemData.append('amount', amount);
    if (link) itemData.append('link', link);
    if (image) itemData.append('image', image);

    try {
      const response = await axiosInstance.post(ASSETS_WISHLIST_APIS, itemData);
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
        setIsDisabled(false);
        setError('');
      }
    } catch (error) {
      setIsDisabled(false);
      if (error.response) {
        setError(
          error.response.data.message || 'An error occurred. Please try again.',
        );
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  }

  return (
    <div className='crate_form'>
      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label={`Name`}
          value={name}
          placeholder={`Enter Name`}
          setState={setName}
        />

        <NumberInput
          label={`Amount`}
          value={amount}
          placeholder={`Enter Amount`}
          setState={setAmount}
        />

        <ShortTextInput
          label={`Link`}
          value={link}
          placeholder={`Enter Link`}
          setState={setLink}
        />

        <ImageInput
          fieldId='1'
          state={image}
          setState={setImage}
          allowCreateImage
        >
          Upload Card Image
        </ImageInput>

        <ErrorMessage error={error} />

        <FormSubmitButton text='Create Assets Wishlist' disabled={isDisabled} />
      </Form>
    </div>
  );
}

export default CreateAssetWishlist;
