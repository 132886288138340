import LazyLoad from "../../../../../Partials/LazyLoad/LazyLoad"

const AssignWorkStation = LazyLoad(()=>import("./AssignWorkStation/AssignWorkStation")) 
const UnassignWorkStation = LazyLoad(()=>import("./UnassignWorkStation/UnassignWorkStation")) 
const WorkStationDashboard = LazyLoad(()=>import("./WorkStationDashboard")) 
const WorkStations = LazyLoad(()=>import("./WorkStations/WorkStations")) 

const WorkStationRoutes = [
    {
      path: 'dashboard',
      element: <WorkStationDashboard />
    },
    {
      path: 'workstations',
      element: <WorkStations />
    },
    {
      path: 'assign',
      element: <AssignWorkStation />
    },
    {
      path: 'unassign',
      element: <UnassignWorkStation />
    },
    
  ]

export default WorkStationRoutes