import LazyLoad from "../../../../../../Partials/LazyLoad/LazyLoad"
import SingleEmployeeRoutes from "./AllEmployees/SingleEmployee/SingleEmployeeRoutes"

const AllEmployees = LazyLoad(() => import('./AllEmployees/AllEmployees'))
const SingleEmployeeScreens = LazyLoad(() => import('./AllEmployees/SingleEmployee/SingleEmployeeScreens'))



const ManageEmployeeRoutes = [
    {
        path: 'dashboard',
        element: <AllEmployees />
    },
    {
        path: 'allEmployees/:employeeFilter',
        element: <AllEmployees />
    },
    {
        path: 'singleEmployee',
        element: <SingleEmployeeScreens />,
        children: SingleEmployeeRoutes
    },
]

export default ManageEmployeeRoutes