import LazyLoad from "../../../Partials/LazyLoad/LazyLoad"
import TechRoutes from "./Tech/TechRoutes"


const EmployeeProjectTechTasks = LazyLoad(() => import("../EmployeeProjectTechTasks/EmployeeProjectTechTasks"))
const AnthillModules = LazyLoad(() => import("./AnthillModules/AnthillModules"))
const AnthillTasks = LazyLoad(() => import("./AnthillTasks/AnthillTasks"))
const CEOPriority = LazyLoad(() => import("./CeoPriority/CeoPriority"))
const ExecutionDashboard = LazyLoad(() => import("./ExecutionDashboard"))
const SnippetCategories = LazyLoad(() => import("./Snippet/SnippetCategories"))
const Snippets = LazyLoad(() => import("./Snippet/Snippets"))
const Tasks = LazyLoad(() => import("./Tasks/Tasks"))
const TechScreens = LazyLoad(() => import("./Tech/TechScreens"))
const TechTasks = LazyLoad(() => import("./TechTasks/TechTasks"))



const ExecutionRoutes = [
  {
    path: 'dashboard',
    element: <ExecutionDashboard />
  },
  {
    path: 'anthillModules',
    element: <AnthillModules />,
  },
  {
    path: 'anthillTasks',
    element: <AnthillTasks />,
  },
  {
    path: 'tasks',
    element: <Tasks />,
  },
  {
    path: 'techs',
    element: <TechScreens />,
    children: TechRoutes
  },
  {
    path: 'techTasks',
    element: <EmployeeProjectTechTasks />,
  },
  {
    path: 'ceoPriorities',
    element: <CEOPriority />,
  },
  {
    path: 'techTasks/:projectName/:projectID',
    element: <TechTasks />,
  },
  {
    path: 'snippetCategories',
    element: <SnippetCategories />,
  },
  {
    path: 'snippets/:parentID',
    element: <Snippets />,
  }
]

export default ExecutionRoutes