import { MomentSelectLayout } from "anthill-component-library/dist/layouts"
import ProjectButton from "../../Screens/MainScreens/BizDev/ProjectManagement/OldProjects/ProjectButton/ProjectButton"
import SearchInput from "../../Screens/MainScreens/BizDev/ProjectManagement/OldProjects/SearchInput/SearchInput"

import SelectFiltering from "../SelectFiltering/SelectFiltering"
import FilterAffiliate from "../../Screens/MainScreens/Acquisition/AffiliateManagement/Affiliates/FilterAffiliate/FilterAffiliate"
import HeadingText from "./HeadingText/HeadingText"
import HeaderWrapper from "./HeaderWrapper"




function HeaderWithtFilterButton({
    // filtering
    filter, setFilter, filterValues, setShowFilterProject, showFilterProject,
    hasSecondFilter, secondFilterValues, secondFilter, setSecondFilter,
    // search 
    search, setSearch, showSearch, setShowSearch,

    // create item
    hasCreate, setShowCreateForm, headingText, screenTopicSingular,

    // only for Affiliate
    tier, setTier, type, setType,

    // to hidden icon
    hasFilterIcon,
    hasSearchIcon,

    // hidden create icon when date is not today only for task
    createAdditionalCondition,

    // date filter
    dateFiltering,
    selectedYear, setSelectedYear,
    selectedMonth, setSelectedMonth,
    selectedDate, setSelectedDate,
    daysInMonth

}) {


    return (
        <HeaderWrapper>
            <HeadingText headingText={headingText} />
            {/* Only for date where need */}
            {
                dateFiltering &&
                <MomentSelectLayout
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    daysInMonth={daysInMonth}
                />
            }

            {/* Search */}
            {
                showSearch &&
                <SearchInput
                    search={search}
                    setSearch={setSearch}
                />
            }

            {/* Filter Any */}
            {
                showFilterProject &&
                <SelectFiltering
                    value={filter}
                    setState={setFilter}
                    values={filterValues}
                />
            }
            {
                hasSecondFilter &&
                <SelectFiltering
                    value={secondFilter}
                    setState={setSecondFilter}
                    values={secondFilterValues}
                />
            }

            {/* Filtering only for Affiliate */}
            {
                tier && type &&
                <FilterAffiliate
                    type={type}
                    setType={setType}
                    tier={tier}
                    setTier={setTier} />
            }

            {/* For All Button */}
            <ProjectButton
                hasSearchIcon={hasSearchIcon}
                hasFilterIcon={hasFilterIcon}
                dateFiltering={dateFiltering}
                setSearch={setSearch}
                setShowSearch={setShowSearch}
                setShowCreateForm={setShowCreateForm}
                hasCreate={hasCreate}
                screenTopicSingular={screenTopicSingular}
                setShowFilterProject={setShowFilterProject}
                createAdditionalCondition={createAdditionalCondition}
            />

        </HeaderWrapper>
    )
}

export default HeaderWithtFilterButton