import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"
import SlackRoutes from "./Slack/SlackRoutes"

const AppIntegrationDashboard = LazyLoad(() => import("./AppIntegrationDashboard"))
const SlackScreens = LazyLoad(() => import("./Slack/SlackScreens"))

const AppIntegrationRoutes = [
  {
    path: 'dashboard',
    element: <AppIntegrationDashboard />
  },
  {
    path: 'slack',
    element: <SlackScreens />,
    children: SlackRoutes
  },

]

export default AppIntegrationRoutes