import Select from "./Select/Select";


function FilterAffiliate({ type, setType, setTier, tier }) {

    const localForeignOptions = [
        { value: 'all', label: 'All' },
        { value: 'local', label: 'Local' },
        { value: 'foreign', label: 'Foreign' },
        { value: 'archive', label: 'Archived' }
    ];

    const tierOptions = [
        { value: 1, label: 'Tier 1' },
        { value: 2, label: 'Tier 2' },
        { value: 3, label: 'Tier 3' },
        { value: 4, label: 'Tier 4' },
    
    ];

    return ( 
        <>
            <Select
                options={localForeignOptions}
                value={type}
                setState={setType}
            />

            <Select
                options={tierOptions}
                value={tier}
                setState={setTier}
            />

        </>
    );
};

export default FilterAffiliate;
