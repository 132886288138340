import LazyLoad from '../../../../Partials/LazyLoad/LazyLoad';

import ChangemakerRoutes from './Changemakers/ChangemakerRoutes';
import EmployeeRoutes from './Employees/EmployeeRoutes';

const UserDashboard = LazyLoad(() => import('./UserDashboard'));
const ChangemakerScreens = LazyLoad(() => import('./Changemakers/ChangemakerScreens'));
const EmployeeScreens = LazyLoad(() => import('./Employees/EmployeeScreens'));


const UserRoutes = [
  {
    path: 'dashboard',
    element: <UserDashboard />
  },
  {
    path: 'employees',
    element: <EmployeeScreens />,
    children: EmployeeRoutes
  },
  {
    path: 'changemakers',
    element: <ChangemakerScreens />,
    children: ChangemakerRoutes
  }
]

export default UserRoutes