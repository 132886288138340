import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"

const AccountManagementDashboard = LazyLoad(() => import("./AccountManagementDashboard"))
const AccountTypes = LazyLoad(() => import("./AccountTypes/AccountTypes"))
const Accounts = LazyLoad(() => import("./Accounts/Accounts"))

const AccountManagementRoutes = [
  {
    path: 'dashboard',
    element: <AccountManagementDashboard />
  },
  {
    path: 'accounts',
    element: <Accounts />,
  },
  {
    path: 'types',
    element: <AccountTypes />,
  },
]

export default AccountManagementRoutes