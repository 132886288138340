

import { RiFolder2Fill, RiUser2Fill } from 'react-icons/ri'
import { NavCardList, NavCard } from 'anthill-component-library/layouts/master'

export const hrRecruitmentRoute = '/main/hr/recruitment/'

function RecruitmentDashboard() {
    return (
        <NavCardList numOfCards='two'>
            <NavCard cardName='Recruitment Fb Groups' navCardLink={hrRecruitmentRoute + 'recruitmentFbGroups'}><RiUser2Fill /></NavCard>
            <NavCard cardName='Designation Fb Groups' navCardLink={hrRecruitmentRoute + 'designationFbGroups'}><RiFolder2Fill /></NavCard>

        </NavCardList>
    )
}

export default RecruitmentDashboard

