import { useLevels } from "anthill-component-library/dist/hooks";
import { RiAddCircleFill, RiFilterLine, RiSearchLine } from "react-icons/ri";

function ProjectButton({
    hasFilterIcon = true, hasSearchIcon = true, screenTopicSingular,
    setSearch, setShowSearch,
    setShowCreateForm,
    setShowFilterProject, hasCreate = true, createAdditionalCondition = false }) {

    const { admin, superAdmin } = useLevels();
    const canShowCreateButton = createAdditionalCondition ? hasCreate : hasCreate || admin || superAdmin

    return (
        <div className="project_btn">

            {
                hasSearchIcon && <button className="btn_create" onClick={() => {
                    setShowSearch(prev => !prev);
                    if (setShowFilterProject) {
                        setShowFilterProject(false);
                    }
                    setSearch('');
                }}>
                    <RiSearchLine />
                </button>

            }
            {
                hasFilterIcon && <button className="btn_create" onClick={() => {
                    setShowFilterProject(prev => !prev);
                    if (setShowSearch) {
                        setShowSearch(false);
                    }
                    if (setSearch) {
                        setSearch("")
                    }
                }}>
                    <RiFilterLine />
                </button>
            }

            {canShowCreateButton && (
                <button className="btn_create" onClick={() =>
                    setShowCreateForm(true)}>
                    <RiAddCircleFill />
                    {screenTopicSingular && <span>{`Create New ${screenTopicSingular}`}</span>}
                </button>
            )}
        </div>
    );
}

export default ProjectButton;
