import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"

const CRMAddContactDashboard = LazyLoad(() => import("./CRMAddContactDashboard/CRMAddContactDashboard"))
const CRMContact = LazyLoad(() => import("./CRMContact/CRMContact"))
const CRMDashboard = LazyLoad(() => import("./CRMDashboard/CRMDashboard"))


const CRMRoutes = [
  {
    path: 'dashboard',
    element: <CRMDashboard />
  },

  {
    path: 'contactsByPlatform',
    element: <CRMAddContactDashboard />
  },
  {
    path: 'contactsByPlatform/:source',
    element: <CRMContact />
  },

]

export default CRMRoutes