import React from 'react';

// const RenderCell = ({ userId = '', dayIndex = '', entries }) => {
//   return (
//     <div className='gantt-chart-data text-black'>
//       <div className='gantt-header-users-container'>
//         <div className='gantt-header-users'>User</div>
//       </div>
//       <div className='progress-container'>
//         <div className='progress'>Project1</div>
//         <div className='progress'>Project1</div>
//         <div className='progress'>Project1</div>
//         <div className='progress'>Project1</div>
//       </div>
//       <div className='progress-container'>
//         <div className='progress' />
//         <div className='progress'>Project2</div>
//         <div className='progress'>Project2</div>
//       </div>
//       <div className='progress-container'>
//         <div className='progress'>Project3</div>
//         <div className='progress'>Project3</div>
//         <div className='progress'>Project3</div>
//       </div>
//       <div className='progress-container'>
//         <div className='progress'>Project4</div>
//       </div>
//       <div className='progress-container'>
//         <div className='progress'>Project5</div>
//         <div className='progress'>Project5</div>
//       </div>
//     </div>
//   );
// };

const RenderData = ({ userId = '', dayIndex = '', entries }) => {
  return (
    <div className='gantt_data'>
      {/* <!-- (A) FIRST ROW : DAYS --> */}
      <img
        src='https://images.pexels.com/photos/593655/pexels-photo-593655.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
        alt='user'
        className='user_image'
        style={{
          // gridRow: 2,
          gridRow: '1 / span 5',
        }}
      />
      {/* <!-- (B) FOLLOWING : TASKS --> */}
      <div
        className='progress text_center text_black'
        style={{
          backgroundColor: '#ffdddd',
          gridRow: 2,
          gridColumn: '2 / span 2',
          padding: '5px',
        }}
      >
        First
      </div>
      <div
        className='progress text_center text_black'
        style={{
          backgroundColor: '#e2e5ff',
          gridRow: 3,
          gridColumn: '2 / span 1',
          padding: '5px',
        }}
      >
        First
      </div>
      <div
        className='progress text_center text_black'
        style={{
          backgroundColor: '#ffdddd',
          gridRow: 3,
          gridColumn: '3 / span 2',
          padding: '5px',
        }}
      >
        First
      </div>
      <div
        className='progress text_center text_black'
        style={{
          background: '#d6ffd8',
          gridRow: 4,
          gridColumn: '4 / span 3',
          padding: '5px',
        }}
      >
        Second
      </div>
      <div
        className='progress text_center text_black'
        style={{
          background: '#e2e5ff',
          gridRow: 5,
          gridColumn: '3 / span 3',
          padding: '5px',
        }}
      >
        Third
      </div>
    </div>
  );
};

function GanttChart({ users, projects, entries, startDate, openModal }) {
  console.log('users', users);
  // Function to get entries for a specific user and day index
  // const getEntriesForDay = (userId, dayIndex) => {
  //   const currentDay = new Date(startDate);
  //   currentDay.setDate(startDate.getDate() + dayIndex);

  //   return entries.filter((entry) => {
  //     const projectStart = new Date(entry.startDate);
  //     const projectEnd = new Date(entry.endDate);

  //     return (
  //       entry.userId === userId &&
  //       currentDay >= projectStart &&
  //       currentDay <= projectEnd
  //     );
  //   });
  // };

  // // Function to render individual cells in the Gantt chart
  // const renderCell = (userId, dayIndex) => {
  //   const dayEntries = getEntriesForDay(userId, dayIndex);

  //   if (dayEntries.length > 0) {
  //     return (
  //       <div className='gantt-cell'>
  //         {dayEntries.map((entry) => {
  //           const project = projects.find((p) => p.id === entry.projectId);

  //           // Handle case where the project is not found
  //           if (!project) {
  //             console.error(`Project with ID ${entry.projectId} not found.`);
  //             return (
  //               <div key={entry.id} style={{ backgroundColor: 'grey' }}>
  //                 Unknown Project
  //               </div>
  //             );
  //           }

  //           return (
  //             <div
  //               key={entry.id}
  //               style={{
  //                 backgroundColor: project.color,
  //               }}
  //             >
  //               {project.name}
  //             </div>
  //           );
  //         })}
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div className='gantt-cell' onClick={() => openModal(userId)}>
  //         +
  //       </div>
  //     );
  //   }
  // };

  return (
    <div className='flex_container'>
      <div className='flex-1'>
        {/* gannt chart */}
        <div class='gantt_head'>
          {/* <!-- (A) FIRST ROW : DAYS --> */}
          <span />
          <div className='head text_center text_black'>Sun</div>
          <div className='head'>Mon</div>
          <div className='head'>Tue</div>
          <div className='head'>Wed</div>
          <div className='head'>Thur</div>
        </div>
        {/* chart data */}
        {users.map((user) => (
          <RenderData
            key={user.id}
            userId={user.id}
            entries={entries.filter((entry) => entry.userId === user.id)}
          />
        ))}

        {/* render cell */}
        {/* {users.map((user) => (
            <RenderCell
              key={user.id}
              userId={user.id}
              entries={entries.filter((entry) => entry.userId === user.id)}
            />
          ))} */}
        {/* <div className='flex-1 gantt-chart-data text-black'>
            <div className='gantt-header-users-container'>
              <div className='gantt-header-users'>User</div>
            </div>
            <div className='progress-container '>
              <div className='progress'>Project1</div>
              <div className='progress'>Project1</div>
              <div className='progress'>Project1</div>
              <div className='progress'>Project1</div>
              <button className='add_progress_btn'>+</button>
            </div>
            <div className='progress-container'>
              <div className='progress'>Project1</div>
              <div className='progress'>Project1</div>
            </div>
            <div className='progress-container'>
              <div className='progress'>Project1</div>
              <div className='progress'>Project1</div>
              <div className='progress'>Project1</div>
            </div>
            <div className='progress-container'>
              <div className='progress'>Project1</div>
            </div>
            <div className='progress-container'>
              <div className='progress'>Project1</div>
              <div className='progress'>Project1</div>
            </div>
          </div> */}
      </div>
      {/* <div>
          {users.map((user) => (
            <div className='gantt-header-users'>{user?.name}</div>
          ))}
        </div> */}
      {/* Projects */}
      <div>Project 1 Project 2 Project 3 Project 4</div>
    </div>
    // <div className='flex_container'>
    //   <div className='gantt-chart flex-1'>
    //     <span className='gantt-header gantt-header-days ' />
    //     <div className='gantt-header gantt-header-days'>SUN</div>
    //     <div className='gantt-header gantt-header-days'>MON</div>
    //     <div className='gantt-header gantt-header-days'>TUE</div>
    //     <div className='gantt-header gantt-header-days'>WED</div>
    //     <div className='gantt-header gantt-header-days'>THU</div>
    //     {users.map((user) => (
    //       <React.Fragment key={user.id}>
    //         <div className='gantt-header gantt-header-users'>{user.name}</div>
    //         {Array.from({ length: 5 }).map((_, i) => (
    //           <React.Fragment key={i}>{renderCell(user.id, i)}</React.Fragment>
    //         ))}
    //       </React.Fragment>
    //     ))}
    //   </div>
    //   <div>Project 1 Project 2 Project 3 Project 4</div>
    // </div>
  );
}

export default GanttChart;
