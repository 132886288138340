import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"

const Subcriptions = LazyLoad(()=>import("./Subcriptions/Subcriptions")) 
const SubscriptionManagementDashboard = LazyLoad(()=>import("./SubscriptionManagementDashboard")) 
const SubcriptionTypes = LazyLoad(()=>import("./SubscriptionTypes/SubcriptionTypes")) 

const SubscriptionManagementRoutes = [
    {
      path: 'dashboard',
      element: <SubscriptionManagementDashboard />
    },
    {
      path: 'types',
      element: <SubcriptionTypes />
    },
    {
      path: 'subscriptions/*',
      element: <Subcriptions />
    }
  ]

export default SubscriptionManagementRoutes