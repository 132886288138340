

import { useEffect, useState } from "react"
import { Form } from "anthill-component-library/dist/layouts"
import { SelectInput, SelectOption } from "anthill-component-library/dist/inputs"
import { FormSubmitButton } from "anthill-component-library/dist/buttons"
import { useAxiosInstance } from "anthill-component-library/dist/hooks"
import { DESIGNATION_FB_GROUP_APIS, RECRUITMENT_FB_GROUP_APIS } from "../../../../../../../../Utilities/APIs/Gamma/HR/HRAPIs"

function CreateDesignationFbGroup({ triggerFetch, setShowCreateForm, designation }) {


    const [recruitmentFbGroups, setRecruitmentFbGroups] = useState(null)
    const [recruitmentFbGroup, setRecruitmentFbGroup] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [error, setError] = useState(false)
    const axiosInstance = useAxiosInstance()


    useEffect(() => {
        async function fetchAndSetDesignations() {
            const { data } = await axiosInstance.get(RECRUITMENT_FB_GROUP_APIS)
            setRecruitmentFbGroups(data);
        }
        fetchAndSetDesignations();
    }, [axiosInstance]);


    async function handleSubmit(e) {
        e.preventDefault()

        if (!recruitmentFbGroup || !designation) {
            setError("Must Add All Field")
            return;
        }
        setIsDisabled(true)

        const itemData = { recruitmentFbGroup, designation }

        try {
            const response = await axiosInstance.post(DESIGNATION_FB_GROUP_APIS, itemData)
            if (response) {
                setShowCreateForm(false)
                triggerFetch()
                setIsDisabled(false)
            }
        } catch (error) {

            if (error.response) {
                setError(`Error: ${error.response.data.message || error.response.statusText}`);
                setIsDisabled(false)
            } else {
                setError(`Error: ${error.message}`);
                setIsDisabled(false)
            }
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit} >
                <div>

                    <SelectInput
                        label={'Select Recruitment Fb Group'}
                        value={recruitmentFbGroup}
                        setState={setRecruitmentFbGroup}
                    >
                        <SelectOption optionText={"Select Recruitment Fb Group"} optionValue={""} />
                        {
                            recruitmentFbGroups?.length > 0 && recruitmentFbGroups?.map(recruitmentFbGroup => (

                                <SelectOption key={recruitmentFbGroup?._id} optionText={recruitmentFbGroup?.name} optionValue={recruitmentFbGroup?._id} />
                            ))
                        }

                    </SelectInput>

                    {error && <p style={{ color: "red" }}>{error}</p>}

                </div>
                <FormSubmitButton text="create Designation Fb Group" disabled={isDisabled} />
            </Form>
        </div>
    )
}

export default CreateDesignationFbGroup