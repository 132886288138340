import { Modal } from "anthill-component-library/dist/elements"
import { useAxiosInstance } from "anthill-component-library/dist/hooks"
import CreateAssetWishlist from "./CreateAssetWishlist"
import UpdateAssetWishlist from "./UpdateAssetWishlist"
import ViewAssetWishlist from "./ViewAssetWishlist"
import { ASSETS_WISHLIST_APIS } from "../../../../../../Utilities/APIs/Gamma/OfficeManagement/OfficeManagementAPIs"
import { ArchiveItem } from "anthill-component-library/dist/ArchiveItem-e0d8fc22"


function AssetsWIshlistModals({
    showCreateForm, setShowCreateForm,
    showUpdateForm, setShowUpdateForm,
    showViewSection, setShowViewSection,
    showArchiveForm, setShowArchiveForm,
    triggerFetch, targetID, isActive,
}) {
    const axiosInstance = useAxiosInstance()
    return (
        <>
            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Asset Wishlist`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateAssetWishlist
                        triggerFetch={triggerFetch}
                        setShowCreateForm={setShowCreateForm}
                    />
                </Modal>
            )}

            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update Asset Wishlist`}
                    setShowModalContent={setShowUpdateForm}
                >
                    <UpdateAssetWishlist
                        triggerFetch={triggerFetch}
                        targetID={targetID}
                        setShowUpdateForm={setShowUpdateForm}
                    />
                </Modal>
            )}

            {showViewSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`View Asset Wishlist`}
                    setShowModalContent={setShowViewSection}
                >
                    <ViewAssetWishlist triggerFetch={triggerFetch} targetID={targetID} />
                </Modal>
            )}
            {showArchiveForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Archive Asset Wishlist`}
                    setShowModalContent={setShowArchiveForm}
                >
                    <ArchiveItem
                        api={ASSETS_WISHLIST_APIS + "archiveAssetsWishlist/"}
                        targetID={targetID}
                        setShowArchiveForm={setShowArchiveForm}
                        triggerFetch={triggerFetch}
                        axiosInstance={axiosInstance}
                        isActive={isActive}
                    />
                </Modal>
            )}

        </>
    )
}

export default AssetsWIshlistModals