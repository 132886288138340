import LazyLoad from "../../../Partials/LazyLoad/LazyLoad"
import AssetsWishlist from "./AssetsWishlist/AssetsWishlist"
import DeviceManagementRoutes from "./DeviceManagement/DeviceManagementRoutes"
import SubscriptionManagementRoutes from "./SubcriptionManagement/SubscriptionManagementRoutes"

const DeviceManagementScreen = LazyLoad(()=>import("./DeviceManagement/DeviceManagementScreen")) 
const OfficeManagementDashboard = LazyLoad(()=>import("./OfficeManagementDashboard")) 
const Rooms = LazyLoad(()=>import("./Rooms/Rooms")) 
const SubcriptionManagementScreen = LazyLoad(()=>import("./SubcriptionManagement/SubcriptionManagementScreen")) 
const Test = LazyLoad(()=>import("./Test/Test")) 

const OfficeManagementRoutes = [
  {
    path: 'dashboard',
    element: <OfficeManagementDashboard />
  },
  {
    path: 'subscriptionManagement',
    element: <SubcriptionManagementScreen />,
    children: SubscriptionManagementRoutes
  },
  {
    path: 'deviceManagement',
    element: <DeviceManagementScreen />,
    children: DeviceManagementRoutes
  },
  {
    path: 'rooms',
    element: <Rooms />,
  },
  {
    path: 'assetsWishlist',
    element: <AssetsWishlist />,
  },
  {
    path: 'test',
    element: <Test />,
  },
]

export default OfficeManagementRoutes