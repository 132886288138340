
function HeaderWrapper({ children }) {

    return (
        < div className="screen_header">
            {children}
        </div>
    )
}

export default HeaderWrapper