import React, { useEffect, useState } from 'react';
import GanttChart from './Components/GanttChart';
import './GanntChartScreens.css';
import AddEntryModal from './Components/AddEntryModal';

function GanttChartScreens() {
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [entries, setEntries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    // Simulating fetching data from a database
    const dummyUsers = [
      {
        id: 1,
        name: 'Alice',
        image:
          'https://images.unsplash.com/photo-1640951613773-54706e06851d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        id: 2,
        name: 'Bob',
        image:
          'https://images.unsplash.com/photo-1640951613773-54706e06851d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        id: 3,
        name: 'Charlie',
        image:
          'https://images.unsplash.com/photo-1640951613773-54706e06851d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
    ];
    const dummyProjects = [
      { id: 1, name: 'Project A', color: '#FF5733' },
      { id: 2, name: 'Project B', color: '#33FF57' },
      { id: 3, name: 'Project C', color: '#3357FF' },
    ];

    const dummyEntries = [
      {
        id: 101,
        userId: 1,
        projectId: 1,
        startDate: new Date(),
        endDate: new Date(new Date().setDate(new Date().getDate() + 2)),
      },
      {
        id: 102,
        userId: 2,
        projectId: 2,
        startDate: new Date(new Date().setDate(new Date().getDate() + 1)),
        endDate: new Date(new Date().setDate(new Date().getDate() + 4)),
      },
      {
        id: 102,
        userId: 1,
        projectId: 2,
        startDate: new Date(new Date().setDate(new Date().getDate() + 1)),
        endDate: new Date(new Date().setDate(new Date().getDate() + 4)),
      },
      {
        id: 103,
        userId: 3,
        projectId: 3,
        startDate: new Date(new Date().setDate(new Date().getDate())),
        endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      },
      {
        id: 103,
        userId: 3,
        projectId: 1,
        startDate: new Date(),
        endDate: new Date(new Date().setDate(new Date().getDate() + 2)),
      },
    ];

    setUsers(dummyUsers);
    setProjects(dummyProjects);
    setEntries(dummyEntries);
  }, []);

  const addEntry = (projectId, startDate, endDate) => {
    const newEntry = {
      id: Date.now(),
      userId: selectedUserId,
      projectId,
      startDate,
      endDate,
    };
    setEntries((prevEntries) => [...prevEntries, newEntry]);
  };

  const openModal = (userId) => {
    setSelectedUserId(userId);
    setIsModalOpen(true);
  };

  return (
    <div className='gannt_chart_container'>
      <div className='vertical_text_container'>
        <h1 className='vertical_text'>May 26 To May 30</h1>
      </div>
      <GanttChart
        users={users}
        projects={projects}
        entries={entries}
        startDate={startDate}
        openModal={openModal}
      />
      {/* <AddEntryModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        projects={projects}
        onSave={addEntry}
      /> */}
    </div>
  );
}

export default GanttChartScreens;
