import { CRUDButton, DeleteButton, EditButton, ViewButton } from "anthill-component-library/dist/buttons";
import { IconThreeDots } from "antopolis-react-icons";
import { RiArchive2Line } from "react-icons/ri";


function CRUDButtonGroup({
    data,
    setIsActive,
    hasEdit = true,hasView = true,
    targetID, setTargetID,
    setShowViewSection, setShowUpdateForm,
    setShowArchiveForm, setShowDeleteSection,
    portrait, showActionButton, setShowActionButton,
    extraButtons,
    additionalEditCondition = true,
}) {

    return (
        <td>
            <div className="action_buttons_wrapper">
                <button
                    className="three_dots"
                    onClick={() => {
                        if (targetID === data._id) {
                            setTargetID('')
                        } else {
                            setShowActionButton(true);
                            setTargetID(data._id);
                        }

                    }}
                >
                    <IconThreeDots />
                </button>

                {!portrait && (
                    <div className="action_buttons">
                        {
                            hasView &&
                            <ViewButton
                                setShowViewSection={setShowViewSection}
                                targetID={data._id}
                                setTargetID={setTargetID}
                            />
                        }

                        {
                            additionalEditCondition && hasEdit &&
                            <EditButton
                                setShowUpdateForm={setShowUpdateForm}
                                targetID={data?._id}
                                setTargetID={setTargetID}
                            />
                        }
                        {
                            setShowArchiveForm && <CRUDButton
                                handleClick={() => {
                                    setTargetID(data._id)
                                    setShowArchiveForm(true)
                                    setIsActive(data?.isActive)
                                }}
                            >
                                <RiArchive2Line />
                            </CRUDButton>
                        }
                        {
                            setShowDeleteSection && <DeleteButton
                                setShowDeleteSection={setShowDeleteSection}
                                targetID={data._id}
                                setTargetID={setTargetID}
                            />
                        }

                        {
                            extraButtons && extraButtons?.map((button, index) => (
                                <CRUDButton key={index} handleClick={button.handleClick}>
                                    {button?.children}
                                </CRUDButton>
                            ))
                        }

                    </div>
                )}

                {portrait &&
                    showActionButton &&
                    targetID === data._id && (
                        <div className="action_buttons">
                            {
                                setShowViewSection &&
                                <ViewButton
                                    setShowViewSection={setShowViewSection}
                                    targetID={data._id}
                                    setTargetID={setTargetID}
                                    setShowActionButton={setShowActionButton}
                                />
                            }

                            {
                                setShowUpdateForm &&
                                <EditButton
                                    setShowUpdateForm={setShowUpdateForm}
                                    targetID={data._id}
                                    setTargetID={setTargetID}
                                    setShowActionButton={setShowActionButton}
                                />
                            }

                            {
                                setShowArchiveForm &&
                                <CRUDButton
                                    handleClick={() => {
                                        setTargetID(data._id)
                                        setShowArchiveForm(true)
                                        setIsActive(data?.isActive)
                                        setShowActionButton(false)
                                    }}
                                >
                                    <RiArchive2Line />
                                </CRUDButton>
                            }

                            {
                                extraButtons && extraButtons?.map((button, index) => (
                                    <CRUDButton key={index} handleClick={button.handleClick}>
                                        {button?.children}
                                    </CRUDButton>
                                ))
                            }

                        </div>
                    )}

            </div>
        </td>
    )
}

export default CRUDButtonGroup