import BackButton from "../../Elements/BackButton/BackButton"

function HeadingText({ headingText }) {

    return (
        
        <h1 className="screen_heading">
             <BackButton />
            {headingText}
        </h1>
    )
}

export default HeadingText