import LazyLoad from "../../../Partials/LazyLoad/LazyLoad"
import ClientRoutes from "./Clients/ClientRoutes"
import StackholderRoutes from "./Stakeholder/StackholderRoutes"

const RelationshipDashboard = LazyLoad(()=>import("./RelationshipDashboard")) 
const ClientScreens = LazyLoad(()=>import("./Clients/ClientScreens")) 
const AllContacts = LazyLoad(()=>import( "./Contacts/AllContacts/AllContacts"))
const StackholderScreens = LazyLoad(()=>import("./Stakeholder/StackholderScreens"))

const RelationshipRoutes = [
    {
      path: 'dashboard',
      element: <RelationshipDashboard />
    },
    {
      path: 'clients',
      element: <ClientScreens />,
      children: ClientRoutes
    },
    {
      path: 'contacts',
      element: <AllContacts />,
    },
    {
      path: 'stackholders',
      element: <StackholderScreens />,
      children: StackholderRoutes
    },
]

export default RelationshipRoutes