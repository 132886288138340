import { useState, useEffect } from 'react';
import { ASSETS_WISHLIST_APIS,} from '../../../../../../Utilities/APIs/Gamma/OfficeManagement/OfficeManagementAPIs';
import { Form } from 'anthill-component-library/layouts/master';
import { FormSubmitButton } from 'anthill-component-library/elements/buttons';
import {
  NumberInput,
  ShortTextInput,
} from 'anthill-component-library/elements/inputs';
import { ImageInput } from 'anthill-component-library/dist/ImageInput-8f19db3d';
import { useAxiosInstance } from 'anthill-component-library/hooks/master';
import ErrorMessage from '../../../../../Partials/ErrorMessage/ErrorMessage';

function UpdateAssetWishlist({
  targetID,
  employee,
  triggerFetch,
  setShowUpdateForm,
}) {
  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);
  const [link, setLink] = useState('');
  const [image, setImage] = useState('');

  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetItem() {
      const { data } = await axiosInstance.get(
        ASSETS_WISHLIST_APIS + 'getSingleAssetsWishlist/' + targetID,
      );

      setName(data.name);
      setAmount(data.amount);
      setImage(data?.image || '');
      setLink(data?.link || '');
    }
    fetchAndSetItem();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    if (!name || !amount) {
      setError('Must Add (Name,Amount)');
      return;
    }
    setIsDisabled(true);

    const itemData = new FormData();
    itemData.append('name', name);
    itemData.append('amount', amount);
    if (link) itemData.append('link', link);
    if (image) itemData.append('image', image);

    try {
      const response = await axiosInstance.patch(
        ASSETS_WISHLIST_APIS + targetID,
        itemData,
      );
      if (response) {
        triggerFetch();
        setShowUpdateForm(false);
        setIsDisabled(false);
        setError('');
      }
    } catch (error) {
      setIsDisabled(false);
      if (error.response) {
        setError(
          error?.response?.data?.message ||
            'An error occurred. Please try again.',
        );
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  }
  return (
    <div className='crate_form'>
      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label={`Name`}
          value={name}
          placeholder={`Enter Name`}
          setState={setName}
        />

        <NumberInput
          label={`Amount`}
          value={amount}
          placeholder={`Enter Amount`}
          setState={setAmount}
        />

        <ShortTextInput
          label={`Link`}
          value={link}
          placeholder={`Enter Link`}
          setState={setLink}
        />
        <ImageInput
          fieldId='1'
          state={image}
          setState={setImage}
          allowUpdateImage={image}
          allowCreateImage={!image}
        >
          Upload Card Image
        </ImageInput>

        <ErrorMessage error={error} />
        <FormSubmitButton text='Update Assets Wishlist' disabled={isDisabled} />
      </Form>
    </div>
  );
}

export default UpdateAssetWishlist;
