import LazyLoad from "../../../../../Partials/LazyLoad/LazyLoad"


const Investor =LazyLoad(()=>import("./Investor/Investor")) 
const InvestorManagementDashboard =LazyLoad(()=>import("./InvestorManagementDashboard")) 
const InvestorStatus =LazyLoad(()=>import("./InvestorStatus/InvestorStatus")) 


const InvestorManagementRoutes = [
  {
    path: 'dashboard',
    element: <InvestorManagementDashboard />
  },
  {
    path: 'investors',
    element: <Investor />,
  },
  {
    path: 'investorStatus',
    element: <InvestorStatus />,
  },

]

export default InvestorManagementRoutes