import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"
import WorkStationRoutes from "./WorkStationManagement/WorkStationRoutes"

const DeviceManagementDashboard = LazyLoad(()=>import("./DeviceManagementDashboard")) 
const DeviceTypes = LazyLoad(()=>import("./DeviceTypes/DeviceTypes")) 
const Devices = LazyLoad(()=>import("./Devices/Devices")) 
const WorkStationScreens = LazyLoad(()=>import("./WorkStationManagement/WorkStationScreens")) 

const DeviceManagementRoutes = [
    {
      path: 'dashboard',
      element: <DeviceManagementDashboard />
    },
    {
      path: 'types',
      element: <DeviceTypes />
    },
    {
      path: 'devices',
      element: <Devices />
    },
    {
      path: 'workstationManagement',
      element: <WorkStationScreens />,
      children: WorkStationRoutes
    }
    
  ]

export default DeviceManagementRoutes