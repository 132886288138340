import { CLUseParams } from "anthill-component-library/dist/helpers"
import { useEffect, useState } from "react"
import { DESIGNATION_FB_GROUP_APIS} from "../../../../../../../Utilities/APIs/Gamma/HR/HRAPIs"
import useFormStateUtils from "../../../../../../../Utilities/Hooks/useFormStateUtils"
import HeaderWithtFilterButton from "../../../../../../Partials/HeaderWithFilterButton/HeaderWithtFilterButton"
import { CRUDTable } from "anthill-component-library/dist/crudBuilder"
import TableHeader from "../../../../../../Partials/Table/TableHeader/TableHeader"
import TableBody from "../../../../../../Partials/Table/TableBody/TableBody"

import { Modal } from "anthill-component-library/dist/Modal-8ec5e978"
import CreateDesignationFbGroup from "./SingleDesignationFbGroupModals/CreateDesignationFbGroup"

function SingleDesignationFbGroup() {

    const { designationID } = CLUseParams()
    const [fbGroups, setFbGroups] = useState(null)
    const {
        showCreateForm, setShowCreateForm,
        showUpdateForm, setShowUpdateForm,
        showViewSection, setShowViewSection,
        search, setSearch,
        showSearch, setShowSearch,
        showFilterProject, setShowFilterProject,
        targetID, setTargetID,
        isActive, setIsActive,
        filter, setFilter,
        toggleFetch,
        triggerFetch, portrait, setShowActionButton, showActionButton,
        axiosInstance,
    } = useFormStateUtils({ filterInitialValue: "active" })

    // const axiosInstance = useAxiosInstance()

    useEffect(() => {
        async function fetchAndSetDesignations() {
            const { data } = await axiosInstance.get(DESIGNATION_FB_GROUP_APIS + `?designation=${designationID}`)
            setFbGroups(data);
        }
        fetchAndSetDesignations();
    }, [axiosInstance, designationID]);

    return (
        <div className="screen_wrapper">
            <HeaderWithtFilterButton
            hasSearchIcon={false}
            hasFilterIcon={false}
                filter={filter}
                setFilter={setFilter}
                filterValues={["active", "inactive"]}
                search={search}
                setSearch={setSearch}
                showSearch={showSearch}
                setShowSearch={setShowSearch}
                setShowCreateForm={setShowCreateForm}
                setShowFilterProject={setShowFilterProject}
                showFilterProject={showFilterProject}
                headingText={`Designation Fb Groups (${fbGroups?.length > 0 ? fbGroups?.length : 0} in total)`}
            />

            <CRUDTable>

                <TableHeader headers={[" Group", "Link"]}/>
                <TableBody
                    items={fbGroups}
                    displayItems={[
                        { value: "recruitmentFbGroup.name" },
                        { value: "recruitmentFbGroup.link" }
                    ]}
                    targetID={targetID}
                    // hasView={false}
                    hasEdit={false}
                    setTargetID={setTargetID}
                    setShowViewSection={setShowViewSection}
                    setShowUpdateForm={setShowUpdateForm}
                    portrait={portrait}
                    showActionButton={showActionButton}
                    setShowActionButton={setShowActionButton}
                    setIsActive={setIsActive}
                
                />

            </CRUDTable>



            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Designation Fb Group`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateDesignationFbGroup
                        designation={designationID}
                        triggerFetch={triggerFetch}
                        setShowCreateForm={setShowCreateForm}
                    />
                </Modal>
            )}

            

        </div>
    )
}

export default SingleDesignationFbGroup






