export const filterEntries = (data = [], filter) => {
  const lockedEntries = data.filter((entry) => entry.probability === 'Locked');
  const HighEntries = data.filter((entry) => entry.probability === 'High');
  const LowEntries = data.filter((entry) => entry.probability === 'Low');

  switch (filter) {
    case 'Locked':
      return [...lockedEntries];
    case 'High':
      return [...lockedEntries, ...HighEntries];
    case 'Low':
      return [...lockedEntries, ...HighEntries, ...LowEntries];
    default:
      return data;
  }
};
