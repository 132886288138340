import LazyLoad from "../../../Partials/LazyLoad/LazyLoad"
import WebsiteContentRoutes from "./WebsiteContent/WebsiteContentRoutes"

const MarketingDashboard =LazyLoad(()=>import("./MarketingDashboard")) 
const SuccessStories =LazyLoad(()=>import("./SuccessStories/SuccessStories")) 
const WebsiteContentScreens =LazyLoad(()=>import("./WebsiteContent/WebsiteContentScreens")) 

const MarketingRoutes = [
    {
        path: 'dashboard',
        element: <MarketingDashboard />
    },
    {
        path: 'websiteContent',
        element: <WebsiteContentScreens />,
        children: WebsiteContentRoutes
    },
    {
        path: 'successStories',
        element: <SuccessStories />
    },

]

export default MarketingRoutes