import LazyLoad from "../../../../../../../Partials/LazyLoad/LazyLoad"

const Projects = LazyLoad(() => import("./Projects/Projects"))
const ProjectsStatusDashboard = LazyLoad(() => import("./ProjectsStatusDashboard"))


const ProjectsStatusRoutes = [
    {
        path: 'dashboard',
        element: <ProjectsStatusDashboard />
    },
    {
        path: 'projects/:slug',
        element: <Projects />
    },
    {
        path: 'projects/:type/:slug',
        element: <Projects />
    },


]

export default ProjectsStatusRoutes