import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"
import DesignationFbGroup from "./DesignationFbGroup/DesignationFbGroup"
import SingleDesignationFbGroup from "./DesignationFbGroup/SingleDesignationFbGroup/SingleDesignationFbGroup"
import RecruitmentFbGroups from "./RecruitmentFbGroups/RecruitmentFbGroups"


const RecruitmentDashboard = LazyLoad(() => import("./RecruitmentDashboard"))


const RecruitmentRoutes = [
    {
        path: 'dashboard',
        element: <RecruitmentDashboard />
    },
    {
        path: 'recruitmentFbGroups',
        element: <RecruitmentFbGroups />
    },
    {
        path: 'designationFbGroups',
        element: <DesignationFbGroup />
    },
    {
        path: 'fbGroups/:designationID',
        element: <SingleDesignationFbGroup />
    },

]

export default RecruitmentRoutes

