import LazyLoad from "../../../../../Partials/LazyLoad/LazyLoad"
import TypesRoutes from "./TypesScreens/TypesRoutes"

const ClientTypesDashboard = LazyLoad(()=>import("./ClientTypesDashboard")) 
const TypesScreens = LazyLoad(()=>import( "./TypesScreens/TypesScreens"))


const ClientTypesRoutes = [
    {
      path: 'dashboard',
      element: <ClientTypesDashboard />
    },
    {
      path: 'types/:type',
      element: <TypesScreens />,
      children: TypesRoutes
    },
    {
      path: 'types',
      element: <TypesScreens />,
      children: TypesRoutes
    },
   
    
  ]

export default ClientTypesRoutes