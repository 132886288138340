import LazyLoad from "../../../../../../Partials/LazyLoad/LazyLoad"

const  AllClients =LazyLoad(()=>import("../../AllClients/AllClients")) 
const  TypesDashboard =LazyLoad(()=>import("./TypesDashboard")) 


const TypesRoutes = [
    {
      path: 'dashboard',
      element: <TypesDashboard />
    },
    {
        path: 'clients/:type/:clientFilter',
        element: <AllClients />
      },
   
    
  ]

export default TypesRoutes