import { useState, useEffect } from 'react';
import { Image } from 'anthill-component-library/elements/elements';
import { useAxiosInstance } from 'anthill-component-library/hooks/master';
import { ASSETS_WISHLIST_APIS } from '../../../../../../Utilities/APIs/Gamma/OfficeManagement/OfficeManagementAPIs';

function ViewAssetWishlist({ targetID }) {
  const [item, setItem] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetItem() {
      const { data } = await axiosInstance.get(
        ASSETS_WISHLIST_APIS + 'getSingleAssetsWishlist/' + targetID,
      );
      setItem(data);
    }
    fetchAndSetItem();
  }, [targetID, axiosInstance]);

  return (
    <div className='crud_view_content'>
      {item && (
        <>
          <h1>Name</h1>
          <p>{item?.name || 'Unknown'}</p>

          <h1>Amount</h1>
          <p>{item?.amount || '---'}</p>

          {item?.image && (
            <>
              <h1>Image</h1>
              <Image imgLink={item.image} imgAlt={'wishlist'} className={''} />
            </>
          )}
          {item?.link && (
            <>
              <h1>Link</h1>
              <a
                href={`${item?.link}`}
                target='_blank'
                rel='noreferrer'
                style={{ color: 'white', textDecoration: 'underline' }}
              >
                {item?.link}
              </a>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ViewAssetWishlist;
