import LazyLoad from '../../../Partials/LazyLoad/LazyLoad';

import UserRoutes from './Users/UserRoutes';
import SBURoutes from './SBU/SBURoutes';
import AppIntegrationRoutes from './AppIntegration/AppIntegrationRoutes';
import ProjectManagementRoutes from './ProjectManagement/ProjectManagementRoutes';


const BizDevDashboard = LazyLoad(() => import('./BizDevDashboard'));
const SBUScreens = LazyLoad(() => import('./SBU/SBUScreens'));
const UserScreens = LazyLoad(() => import('./Users/UserScreens'));
const Organizations = LazyLoad(() => import('../Acquisition/Organizations/Organizations'));
const AppIntegrationScreens = LazyLoad(() => import('./AppIntegration/AppIntegrationScreens'));
const ProjectManagementScreens = LazyLoad(() => import('./ProjectManagement/ProjectManagementScreens'));


const BizDevRoutes = [
  {
    path: 'dashboard',
    element: <BizDevDashboard />
  },
  {
    path: 'users',
    element: <UserScreens />,
    children: UserRoutes
  },

  {
    path: 'projectManagement',
    element: <ProjectManagementScreens />,
    children: ProjectManagementRoutes
  },
  {
    path: 'organizations',
    element: <Organizations />
  },

  {
    path: 'appIntegration',
    element: <AppIntegrationScreens />,
    children: AppIntegrationRoutes
  },
  {
    path: 'sbu',
    element: <SBUScreens />,
    children: SBURoutes
  },

]

export default BizDevRoutes