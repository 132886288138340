

function Select({ label, options, value, setState }) {
    return (
        <div className="filter_container">
            <select
                className='filter_select'
                value={value}
                onChange={(e) => setState(e.target.value)}>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Select;


