import { GAMMA_APIS } from "../GammaAPIs";

export const OFFICE_MANAGEMENT_APIS = GAMMA_APIS + 'officeManagement/'
export const ROOMS_APIS = OFFICE_MANAGEMENT_APIS + 'rooms/'
export const WORK_STATIONS_APIS = OFFICE_MANAGEMENT_APIS + 'workStations/'
export const DEVICE_TYPES_APIS = OFFICE_MANAGEMENT_APIS + 'deviceTypes/'
export const DEVICES_APIS = OFFICE_MANAGEMENT_APIS + 'devices/'
export const SUBSCRIPTION_TYPES_APIS = OFFICE_MANAGEMENT_APIS + 'subscriptionTypes/'
export const SUBSCRIPTIONS_APIS = OFFICE_MANAGEMENT_APIS + 'subscriptions/'
export const PURCHASE_INVOICES_APIS = OFFICE_MANAGEMENT_APIS + 'purchaseInvoices/'
export const ASSETS_WISHLIST_APIS = OFFICE_MANAGEMENT_APIS + 'assetsWishlist/'

