import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"

const TechDashboard = LazyLoad(() => import("./TechDashboard"))
const SingleTechProductDashboard = LazyLoad(() => import("./TechProduct/SingleTechProduct/SingleTechProductDashboard"))
const TechProductApp = LazyLoad(() => import("./TechProduct/SingleTechProduct/TechProductApp/TechProductApp"))
const TechProductEpics = LazyLoad(() => import("./TechProduct/SingleTechProduct/TechProductEpics/TechProductEpics"))
const TechProductStories = LazyLoad(() => import("./TechProduct/SingleTechProduct/TechProductStories/TechProductStories"))
const TechProductUsers = LazyLoad(() => import("./TechProduct/SingleTechProduct/TechProductUsers/TechProductUsers"))
const TechProductDashboard = LazyLoad(() => import("./TechProduct/TechProductDashboard"))
const TechProducts = LazyLoad(() => import("./TechProduct/TechProducts"))
const TechProductAppType = LazyLoad(() => import("./TechProductAppType/TechProductAppType"))
const TechProductProjects = LazyLoad(()=>import ("./TechProduct/SingleTechProduct/TechProductProjects/TechProductProjects"))


const TechRoutes = [
    {
        path: 'dashboard',
        element: <TechDashboard />
    },
    {
        path: 'techProductDashboard',
        element: <TechProductDashboard />
    },
    {
        path: 'techProducts',
        element: <TechProducts />
    },
    {
        path: 'appType',
        element: <TechProductAppType />
    },

    {
        path: 'singleTechProductScreens/:techProductId',
        element: <SingleTechProductDashboard />
    },

    {
        path: 'techProductProjects/:techProductId',
        element: <TechProductProjects />
    },
    {
        path: 'techProductApps/:techProductId',
        element: <TechProductApp />
    },
    {
        path: 'techProductUsers/:techProductId',
        element: <TechProductUsers />
    },
    {
        path: 'techProductEpics/:techProductId',
        element: <TechProductEpics />
    },
    {
        path: 'techProductStories/:techProductId/:epicId',
        element: <TechProductStories />
    },

]

export default TechRoutes