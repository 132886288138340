import LazyLoad from "../../../../../../Partials/LazyLoad/LazyLoad"

const CompanyStructureDashboard = LazyLoad(()=>import('./CompanyStructureDashboard')) 
const Departments = LazyLoad(()=>import('./Departments/Departments')) 

const CompanyStructureRoutes = [
    {
        path: 'dashboard',
        element: <CompanyStructureDashboard />
    },
    {
        path: 'departments/:businessFunction',
        element: <Departments />
    }
]

export default CompanyStructureRoutes