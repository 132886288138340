import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad";

import ClientTypesRoutes from "./ClientTypes/ClientTypesRoutes";

const AllClients = LazyLoad(() => import("./AllClients/AllClients"));
const Clients = LazyLoad(() => import("./AllClients/Clients"));
const ClientContacts = LazyLoad(() => import("./ClientContacts/ClientContacts"));
const ClientInvites = LazyLoad(() => import("./ClientInvites/ClientInvites"));
const ClientDashboard = LazyLoad(() => import("./ClientDashboard"));
const ClientGroups = LazyLoad(() => import("./ClientGroups/ClientGroups"));
const ClientTypesScreens = LazyLoad(() => import("./ClientTypes/ClientTypesScreens"));
const ProjectTechTasks = LazyLoad(() => import("./ProjectsFromClient/ProjectTechTasks/ProjectTechTasks"))
const ProjectsFromClient = LazyLoad(() => import("./ProjectsFromClient/ProjectsFromClient"));
const TechTaskCategory = LazyLoad(() => import("./ProjectsFromClient/TechTaskCategory/TechTaskCategory"));
const ClientContactsClients = LazyLoad(() => import("../ClientContactClients/ClientContactsClients"));


const ClientRoutes = [
  {
    path: "dashboard",
    element: <ClientDashboard />,
  },
  {
    path: "allClients/:clientFilter",
    element: <AllClients />,
  },
  {
    path: "clients/",
    element: <Clients />,
  },
  {
    path: "clientGroups/:parentID",
    element: <ClientGroups />,
  },
  {
    path: "projects/:clientName/:parentID",
    element: <ProjectsFromClient />,
  },

  {
    path: "techTaskCategory/:projectID",
    element: <TechTaskCategory />,
  },
  {
    path: "projectTechTasks/:projectID/:categoryID/:categoryName",
    element: <ProjectTechTasks />,
  },
  {
    path: "contacts",
    element: <ClientContacts />
  },
  {
    path: "invites",
    element: <ClientInvites />
  },
  {
    path: "clientContactClients/:parentID",
    element: <ClientContactsClients />
  },

  {
    path: "clientTypes",
    element: <ClientTypesScreens />,
    children: ClientTypesRoutes,
  },
];

export default ClientRoutes;
