import { lazy, Suspense } from 'react';
import { Loader } from 'anthill-component-library/dist/elements';

function LazyLoad(importFunc, defaultProps = {}) {

    const LazyComponent = lazy(importFunc);

    return (props) => (
        <Suspense fallback={<Loader />}>
            <LazyComponent {...defaultProps} {...props}  />
        </Suspense>
    );
};

export default LazyLoad;
