import { useState, useEffect } from 'react';
import { useAxiosInstance } from 'anthill-component-library/dist/hooks';
import { CASH_TARGET_APIS } from '../../../../../Utilities/APIs/Gamma/Finance/CashTarget/CashTargetApi';
import { CLUseLocation } from 'anthill-component-library/dist/helpers';
import { REVENUE_TARGET_APIS } from '../../../../../Utilities/APIs/Gamma/RevenuTarget/RevenueTargetAPIs';

const useFetchMonthlyTarget = (year, month, toggleFetch) => {
    const [data, setData] = useState(null);
    const [targetID, setTargetID] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const axiosInstance = useAxiosInstance();
    const {pathname} = CLUseLocation()
    const api = pathname === '/main/finance/cashTarget' ? CASH_TARGET_APIS : REVENUE_TARGET_APIS;

    useEffect(() => {
        async function fetchCashTarget() {
            try {
                setLoading(true);
                const { data } = await axiosInstance.get(
                    `${api}?year=${year}&month=${month}`,
                );
                setData(data);
                setTargetID(data?._id);
            } catch (err) {
                setError(err.message || 'Failed to fetch cash target');
            } finally {
                setLoading(false);
            }
        }

        if (month && year) {
            fetchCashTarget();
        }
    }, [year, month, axiosInstance, toggleFetch]);

    return { data, targetID, loading, error };
};

export default useFetchMonthlyTarget;
