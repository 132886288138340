import LazyLoad from "../../../../Partials/LazyLoad/LazyLoad"
import ManageProjectRoutes from "./ManageProjects/ManageProjectsRoutes"
import ProjectStatusRoutes from "./ProjectStatus/ProjectStatusRoutes"


const ManageProjectScreens = LazyLoad(()=>import("./ManageProjects/ManageProjectScreens")) 
const OldProjects = LazyLoad(()=>import( "./OldProjects/OldProjects"))
const ProjectManagementDashboard = LazyLoad(()=>import("./ProjectManagementDashboard")) 
const ProjectStatusScreens = LazyLoad(()=>import("./ProjectStatus/ProjectStatusScreens")) 
const ProjectStatusCategories = LazyLoad(()=>import( "./ProjectStatusCategories/ProjectStatusCategories"))
const ProjectStatus = LazyLoad(()=>import("./ProjectStatusCategories/ProjectStatusSubcategories/ProjectStatus/ProjectStatus")) 
const ProjectStatusSubcategories = LazyLoad(()=>import("./ProjectStatusCategories/ProjectStatusSubcategories/ProjectStatusSubcategories")) 
const AllTechProductProjects = LazyLoad(()=>import('./AllTechProductProjects/AllTechProductProjects'))

const ProjectManagementRoutes = [
    {
        path: 'dashboard',
        element: <ProjectManagementDashboard />
    },
    {
        path: 'manageProjects',
        element: <ManageProjectScreens />,
        children: ManageProjectRoutes
    },
    {
        path: 'allProjects',
        element: <OldProjects />,
       
    },
    {
        path: 'allTechProductProjects',
        element: <AllTechProductProjects />,
       
    },
    {
        path: 'manageProjects/:type',
        element: <ManageProjectScreens />,
        children: ManageProjectRoutes
    },
    {
        path: 'projectStatusCategories',
        element: <ProjectStatusCategories />
    },
    {
        path: 'projectStatusSubcategories/:parentID',
        element: <ProjectStatusSubcategories />
    },
    {
        path: 'projectStatus/:parentID',
        element: <ProjectStatus />
    },
    {
        path: 'projectStatus',
        element: <ProjectStatusScreens />,
        children: ProjectStatusRoutes
    },

]

export default ProjectManagementRoutes