
import LazyLoad from "../../../../../Partials/LazyLoad/LazyLoad"
import AttendanceRoutes from "../../../HR/Attendance/AttendanceRoutes"

const TransactionSubCategoriesFromCategory = LazyLoad(()=>import("./TransactionCategories/TransactionSubcategoriesFromCategory")) 
const AttendanceScreens = LazyLoad(()=>import("../../../HR/Attendance/AttendanceScreens")) 
const TransactionCategories = LazyLoad(()=>import("./TransactionCategories/TransactionCategories")) 
const TransactionManagementDashboard = LazyLoad(()=>import("./TransactionManagementDashboard")) 
const TransactionSubCategories = LazyLoad(()=>import("./TransactionSubCategories/TransactionSubCategories")) 
const TransactionTypes = LazyLoad(()=>import("./TransactionTypes/TransactionTypes")) 

const TransactionManagementRoutes = [
    {
      path: 'dashboard',
      element: <TransactionManagementDashboard />
    },
    {
      path: 'categories',
      element: <TransactionCategories />
    },
    {
      path: 'transactionSubcategoriesFromCategory/:parentID',
      element: <TransactionSubCategoriesFromCategory />
    },
    {
      path: 'subcategories',
      element: <TransactionSubCategories />
    },
    {
      path: 'transactionTypes',
      element: <TransactionTypes />
    },
    {
      path: 'accountManagement',
      element: <AttendanceScreens />,
      children: AttendanceRoutes
    }
  ]

export default TransactionManagementRoutes