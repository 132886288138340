import CRUDButtonGroup from "../../CRUDButtonGroup/CRUDButtonGroup";
import { Image } from "anthill-component-library/elements/elements";
import { ShortTextCell } from "anthill-component-library/crud/builder";
import { CLUseNavigate } from "anthill-component-library/dist/helpers";
import { applyTransform, createExtrabutton, getNestedValue } from "./helperFunctions/helperFunctions";

function TableBody({
    items,
    displayItems,
    setIsActive,
    targetID,
    setTargetID,
    setShowViewSection,
    setShowArchiveForm,
    setShowUpdateForm,
    setShowDeleteSection,
    portrait,
    showActionButton,
    setShowActionButton,
    hasExtraButtonParams,
    route,
    extraButtons,
    additionalEditCondition,
    hasView, hasEdit, hasCreate,
    hasLongText,
}) {
    const navigate = CLUseNavigate();

    return (
        <tbody>
            {items && items.map((item) => (
                <tr key={item._id}>
                    {displayItems.map((field, index) => {
                        const rawValue = getNestedValue(item, field.value);
                        const transformedValue = applyTransform(rawValue, field.transform);

                        return field.type === 'image' ? (
                            <td className="rounded_image" key={index}>
                                <Image imgAlt="contact" imgLink={transformedValue} />
                            </td>
                        ) : hasLongText ? (
                            <td key={index}>
                                {transformedValue?.length > 20 ? transformedValue.slice(0, 20) + "..." : transformedValue}
                            </td>
                        ) : (
                            <ShortTextCell key={index} text={transformedValue} />
                        )
                    })}

                    <CRUDButtonGroup
                        data={item}
                        setIsActive={setIsActive}
                        targetID={targetID}
                        setTargetID={setTargetID}
                        setShowViewSection={setShowViewSection}
                        setShowUpdateForm={setShowUpdateForm}
                        setShowArchiveForm={setShowArchiveForm}
                        setShowDeleteSection={setShowDeleteSection}
                        portrait={portrait}
                        hasView={hasView}
                        hasEdit={hasEdit}
                        hasCreate={hasCreate}
                        showActionButton={showActionButton}
                        setShowActionButton={setShowActionButton}
                        extraButtons={hasExtraButtonParams ? createExtrabutton(item, route, navigate) : extraButtons}
                        additionalEditCondition={additionalEditCondition}
                    />
                </tr>
            ))}
        </tbody>
    );
}

export default TableBody;
