import { RiAddCircleFill, RiEditCircleFill } from "react-icons/ri";
import HeaderWrapper from "../../../../../Partials/HeaderWithFilterButton/HeaderWrapper";
import HeadingText from "../../../../../Partials/HeaderWithFilterButton/HeadingText/HeadingText";
import SelectFiltering from "../../../../../Partials/SelectFiltering/SelectFiltering";
import { monthsArray, monthsMap } from "../../Utils/monthData";
import { CLUseLocation } from "anthill-component-library/dist/helpers";
import { getHeadingText } from "../../Utils/getHeadingText";
import { takaFormatter } from "antopolis-utils/helpers/Formatter";

function Heading({ data,
    month, year,
    setMonth, setYear,
    setcashTargetCreateForm, setcashTargetUpdateForm,
    setEditableData,


}) {
    const { pathname } = CLUseLocation();
    const headingText = getHeadingText(pathname)


    return (
        <HeaderWrapper>
            <HeadingText headingText={headingText} />
            <SelectFiltering
                value={year}
                setState={setYear}
                values={['2024', '2025', '2026', '2027']}
            />
            <SelectFiltering
                value={month}
                setState={setMonth}
                values={monthsArray}
            />
            {data?.month === monthsMap[month] && data?.year === year ? (
                <div className='text-white cash_target add_cash_target'>
                    <span>
                        {`Target: ${data?.cashTarget != null
                            ? takaFormatter(data.cashTarget)
                            : takaFormatter(data?.revenueTarget)}`}
                    </span>
                    <button
                        className='btn_create'
                        onClick={() => {
                            setEditableData(data);
                            setcashTargetUpdateForm(true);
                            setcashTargetCreateForm(false)
                        }}
                    >
                        <span>{`Edit`}</span>
                        <RiEditCircleFill />
                    </button>
                </div>
            ) : (
                <div className='text-white cash_target add_cash_target'>
                    <span>Not Found</span>
                    <button
                        className='btn_create'
                        onClick={() => {
                            setcashTargetCreateForm(true)
                            setcashTargetUpdateForm(false)
                        }}
                    >
                        <span>{`Add`}</span>
                        <RiAddCircleFill />
                    </button>
                </div>
            )}
        </HeaderWrapper>
    )
}

export default Heading