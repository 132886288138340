import LazyLoad from "../../../../../../Partials/LazyLoad/LazyLoad"

const AffiliateContacts = LazyLoad(() => import("./AffiliateContacts/AffiliateContacts"))
const AffiliateStatusDashboard = LazyLoad(() => import("./AffiliateStatusDashboard"))

const AffiliateStatusRoutes = [
    {
        path: ':type',
        element: <AffiliateStatusDashboard />
    },
    {
        path: ':type/:status',
        element: <AffiliateContacts />
    },

]

export default AffiliateStatusRoutes