export const calculateAmountProgress = (
  data = [],
  filteredEntries = [],
  target = 0,
) => {
  const totalAmount = data.reduce((acc, entry) => acc + entry.amount, 0);
  const lockedAmount = filteredEntries
    .filter((entry) => entry.probability === 'Locked')
    .reduce((acc, entry) => acc + entry.amount, 0);
  const highAmount = filteredEntries
    .filter((entry) => entry.probability === 'High')
    .reduce((acc, entry) => acc + entry.amount, 0);
  const lowAmount = filteredEntries
    .filter((entry) => entry.probability === 'Low')
    .reduce((acc, entry) => acc + entry.amount, 0);
  const potentialAmount = filteredEntries
    .filter((entry) => entry.probability === 'Potential')
    .reduce((acc, entry) => acc + entry.amount, 0);

  const baseAmount = target > totalAmount ? target : totalAmount;

  return {
    totalAmount,
    lockedProgress: (lockedAmount / baseAmount) * 100,
    lowProgress: (lowAmount / baseAmount) * 100,
    potentialProgress: (potentialAmount / baseAmount) * 100,
    highProgress: (highAmount / baseAmount) * 100,
    baseAmountDivided: baseAmount,
    amount: [lockedAmount, highAmount, lowAmount, potentialAmount],
  };
};
