import { useState } from 'react';
import {
  DateInput,
  NumberInput,
  SelectInput,
  SelectOption,
  ShortTextInput,
} from 'anthill-component-library/dist/inputs';
import { Form } from 'anthill-component-library/dist/layouts';
import { useAxiosInstance } from 'anthill-component-library/dist/hooks';
import { FormSubmitButton } from 'anthill-component-library/dist/buttons';
import ErrorMessage from '../../../../../Partials/ErrorMessage/ErrorMessage';
import { CASH_TARGET_APIS } from '../../../../../../Utilities/APIs/Gamma/Finance/CashTarget/CashTargetApi';
import { CLUseLocation } from 'anthill-component-library/dist/helpers';
import { REVENUE_TARGET_APIS } from '../../../../../../Utilities/APIs/Gamma/RevenuTarget/RevenueTargetAPIs';

function CreateEntry({
  triggerFetch,
  setShowCreateForm,
  targetID,
  editableData: probability,
}) {
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState(null);

  const { pathname } = CLUseLocation();

  const isCashTarget = pathname === '/main/finance/cashTarget';
  const api = isCashTarget ? CASH_TARGET_APIS : REVENUE_TARGET_APIS;

  const axiosInstance = useAxiosInstance();
  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    if (!name || !amount || !probability || !date)
      return setError('Must Add (Name,  Amount, Probability, Date)');

    setIsDisabled(true);

    const itemData = {
      name,
      amount,
      probability,
      date,
    };
    try {
      const response = await axiosInstance.patch(api + targetID, itemData);
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
        setIsDisabled(false);
        setError('');
      }
    } catch (error) {
      setIsDisabled(false);
      if (error.response) {
        setError(
          error.response.data.message || 'An error occurred. Please try again.',
        );
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  }

  return (
    <div className='crate_form'>
      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label={`Entry Name`}
          value={name}
          placeholder={`Enter Name`}
          setState={setName}
        />
        <NumberInput
          label={`Entry Amount`}
          value={amount}
          placeholder={`Enter Entry Amount`}
          setState={setAmount}
        />

        <DateInput label={'Select Date'} value={date} setState={setDate} />

        <ErrorMessage error={error} />

        <FormSubmitButton text='Save Entry' disabled={isDisabled} />
      </Form>
    </div>
    // </div>
  );
}

export default CreateEntry;
